import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { EyeIcon, EyeOffIcon } from "lucide-react";
import text from "../assets/imgs/superstable_text.svg";
import logo from "../assets/imgs/superstable_logo.svg";
import { ToastContainer, toast } from 'react-toastify';

const PwdReset = () => {
    // eslint-disable-next-line 
    const { resetUid } = useParams();
    const navigate = useNavigate();

    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [success, setSuccess] = useState("");
    // eslint-disable-next-line 
    const [email, setEmail] = useState("customer@gmail.com")
    // eslint-disable-next-line 
    const [loading, setLoading] = useState(false);

    // uncomment the useEffect for prefetching the user's email id based on the userid provided in the route params

    // useEffect(() => {
    //     const fetchUserEmail = async () => {
    //         try {
    //             const response = await fetch(`endpoint..`);
    //             const data = await response.json();

    //             if (response.ok && data.email) {
    //                 setEmail(data.email);
    //                 setLoading(false);
    //             } else {
    //                 toast.error("Invalid or expired reset link.");
    //                 setLoading(false);
    //             }
    //         } catch (err) {
    //             toast.error("Something went wrong. Please try again.");
    //             setLoading(false);
    //         }
    //     };

    //     fetchUserEmail();
    // }, [resetUid]);

    // Validation states
    const [validations, setValidations] = useState({
        password: { isValid: true, message: "" },
        confirmPassword: { isValid: true, message: "" },
    });

    const validatePassword = (password) => {
        return password.length >= 8 && /\d/.test(password);
    };

    const handlePasswordChange = (e) => {
        const { value } = e.target;
        setNewPassword(value);

        setValidations((prev) => ({
            ...prev,
            password: {
                isValid: validatePassword(value),
                message: validatePassword(value) ? "" : "Password must be at least 8 characters and include a number.",
            },
        }));
    };

    const handleConfirmPasswordChange = (e) => {
        const { value } = e.target;
        setConfirmPassword(value);

        setValidations((prev) => ({
            ...prev,
            confirmPassword: {
                isValid: value === newPassword,
                message: value === newPassword ? "" : "Passwords do not match.",
            },
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validatePassword(newPassword)) {
            toast.error("Password must be at least 8 characters long and include a number.")
            return;
        }

        if (newPassword !== confirmPassword) {
            toast.error("Passwords do not match.")
            return;
        }

        // simulated the api request for now... replace it with actual fetch api
        setTimeout(() => {
            setSuccess("Password reset successful! Redirecting to login...");
            toast.success("Password reset successful! Redirecting to login...")
            setTimeout(() => navigate("/"), 3000);
        }, 1500);
    };

    return (
        <div className="min-h-screen flex flex-col gap-4 items-center justify-center bg-gray-100">
            <ToastContainer />

            <div className="flex items-center gap-3 animate-slideDown mr-4">
                <a href="/">
                    <img className="w-12" src={logo} alt="logo" />
                </a>
                <a href="/">
                    <img className="w-56" src={text} alt="logo" />
                </a>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-lg w-96 mb-12">
                <h2 className="text-xl font-intertightbold text-center">Reset Your Password</h2>

                {/* {error && <p className="text-center text-red-500 font-helveticanow">{error}</p>}
                {success && <p className="text-center text-green-500 font-helveticanow">{success}</p>} */}

                {!success && (
                    <form onSubmit={handleSubmit} className="mt-4 font-helveticanow">
                        {/* Email Field (Prepopulated & Disabled) */}
                        <div>
                            <label className="block text-gray-600 text-sm">Email</label>
                            <input
                                type="email"
                                value={loading ? "Loading..." : email}
                                disabled
                                className="w-full p-2 border rounded bg-gray-100"
                            />
                        </div>

                        {/* New Password Field */}
                        <div className="mt-3 relative">
                            <label className="block text-gray-600 text-sm">New Password</label>
                            <input
                                type={showPassword ? "text" : "password"}
                                value={newPassword}
                                onChange={handlePasswordChange}
                                className={`w-full p-2 border rounded ${validations.password.isValid ? "border-gray-300" : "border-red-500"}`}
                                required
                            />
                            <button
                                type="button"
                                onClick={() => setShowPassword(!showPassword)}
                                className="absolute right-3 top-9 text-gray-400 hover:text-blue-500 transition"
                                aria-label={showPassword ? "Hide password" : "Show password"}
                            >
                                {showPassword ? <EyeIcon className="h-5 w-5" /> : <EyeOffIcon className="h-5 w-5" />}
                            </button>
                            {newPassword.length !== 0 & !validations.password.isValid ? (
                                <p className="mt-1 text-sm text-red-600">{validations.password.message}</p>
                            ) : ""}
                        </div>

                        {/* Confirm Password Field */}
                        <div className="mt-3 relative">
                            <label className="block text-gray-600 text-sm">Confirm Password</label>
                            <input
                                type={showConfirmPassword ? "text" : "password"}
                                value={confirmPassword}
                                onChange={handleConfirmPasswordChange}
                                className={`w-full p-2 border rounded ${validations.confirmPassword.isValid ? "border-gray-300" : "border-red-500"}`}
                                required
                            />
                            <button
                                type="button"
                                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                className="absolute right-3 top-9 text-gray-400 hover:text-blue-500 transition"
                                aria-label={showConfirmPassword ? "Hide password" : "Show password"}
                            >
                                {showConfirmPassword ? <EyeIcon className="h-5 w-5" /> : <EyeOffIcon className="h-5 w-5" />}
                            </button>
                            {confirmPassword.length !== 0 && !validations.confirmPassword.isValid ? (
                                <p className="mt-1 text-sm text-red-600">{validations.confirmPassword.message}</p>
                            ) : ""}
                        </div>

                        {/* Submit Button */}
                        <button
                            type="submit"
                            className="w-full mt-4 p-2 bg-green-500 text-white font-intertightbold rounded hover:bg-green-600"
                        >
                            Reset Password
                        </button>
                    </form>
                )}
            </div>
        </div>
    );
};

export default PwdReset;
