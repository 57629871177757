import globe from "../../assets/imgs/globe.png";
import exchange from "../../assets/imgs/exchanges.svg";
import remittance from "../../assets/imgs/remittance.svg";
import market from "../../assets/imgs/marketting.svg";
import payroll from "../../assets/imgs/payroll.svg";
import insurance from "../../assets/imgs/insurance.svg";

const WhoUses = () => {
    return (
        <div className="bg-black text-white pt-16 px-6 flex flex-col w-full overflow-hidden">
            <h2 className="text-3xl font-intertightbold text-center md:text-start md:mx-48">
                Who Uses SuperStable?
            </h2>
            <p className="text-gray-400 font-intertight  text-center md:text-start md:mx-48 mt-2">
                SuperStable is the go-to solution for:
            </p>

            <div className="mt-16 flex flex-col-reverse lg:flex-row items-center lg:items-start justify-center lg:justify-between w-full">
                <div className=" min-w-lg max-w-2xl lg:w-1/2 flex relative z-10 right-10 md:right-2 md:opacity-80 opacity-60 ">
                    <img src={globe} alt="Global Network" className="w-full" />
                </div>

                <div className="w-full lg:w-1/2 grid grid-cols-1 sm:grid-cols-2 gap-6 md:gap-10 relative top-10 md:-top-5 md:right-2 z-20">
                    {[
                        {
                            title: "Crypto Exchanges",
                            description:
                                "Stablecoin deposits, withdrawals, and liquidity management.",
                            icon: exchange,
                        },
                        {
                            title: "Remittance Companies",
                            description: "Enabling instant, low-cost money transfers.",
                            icon: remittance,
                        },
                        {
                            title: "Marketing & Creator Economy",
                            description: "Simplifying global influencer and content payouts.",
                            icon: market,
                        },
                        {
                            title: "Payroll & Business Payments",
                            description:
                                "Efficient payouts to SMEs, freelancers, and remote workers.",
                            icon: payroll,
                        },
                        {
                            title: "Refunds & Insurance",
                            description:
                                "Seamless claims processing with instant settlements.",
                            icon: insurance,
                        },
                    ].map((item, index) => (
                        <div key={index} className="flex items-start gap-4 w-[80%]">
                            <img src={item.icon} alt={item.title} className="w-8 h-8" />
                            <div>
                                <h3 className="text-lg font-intertightbold">{item.title}</h3>
                                <p className="text-gray-400 text-sm font-helveticanow">
                                    {item.description}
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default WhoUses;
