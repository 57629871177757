import { countries } from "../../utils/utils";

const Payout = () => {
    return (
        <main className="w-full flex flex-col items-center justify-center gap-5 md:gap-5 lg:mx-6 xl:mx-10  pt-20 py-10 bg-[#F6F9FC]">
            <h1 className="text-center md:text-start text-3xl lg:text-5xl font-intertightbold">
                Payout Supported To
            </h1>

            <div className="flex justify-center items-center gap-4 flex-wrap my-4 font-intertight font-semibold">
                {countries.map((country) => (
                    <div className="flex gap-2 justify-center items-center rounded-4xl shadow-sm px-3 py-2">
                        <img src={country.logo} alt="india" />
                        <p>{country.countryName}</p>
                    </div>
                ))}
            </div>
        </main>
    );
};

export default Payout;
