import logo from "../../assets/imgs/logo.svg";
import twitter from "../../assets/imgs/twiter.svg";
import linkedin from "../../assets/imgs/linkedin.png";
import { Link } from "react-router-dom";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useState } from "react";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: '1000px',
    maxHeight: '90vh',
    overflowY: 'auto',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

const Footer = () => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <footer className="bg-gray-100 py-6 px-6 w-full font-helveticanow">
            <Modal
                open={open}
                onClose={handleClose}
                className="outline-none"
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h5" component="h2" gutterBottom>
                        KYC/AML Policy - SuperStable (ARBIREM, LLC)
                    </Typography>
                    <Typography id="modal-modal-title" variant="h7" component="h2" gutterBottom>
                        Section 1 : Introduction and Purpose
                    </Typography>
                    <Typography
                        id="modal-modal-description"
                        sx={{ whiteSpace: 'pre-wrap', fontSize: '14px', lineHeight: '1.6' }}
                        component="div"
                    >
                        {`1.1 Introduction

This BSA/AML & OFAC Policy establishes ARBIREM, LLC’s (d/b/a Superstable) framework for compliance with applicable anti-money laundering (AML) and sanctions regulations. ARBIREM is a licensed Money Services Business (MSB) that enables organizations to payout in emerging markets using Stablecoins (USDC). The company’s customers primarily consist of commercial entities operating across various global jurisdictions.
As a financial service provider, ARBIREM recognizes the importance of protecting the financial system from illicit activities, including money laundering, terrorist financing, fraud, and sanctionsolations. This policy outlines the procedures and controls ARBIREM has implemented to ensure compliance with all applicable U.S. laws and regulations, including:
 - The Bank Secrecy Act (BSA) and implementing regulations
 - The USA PATRIOT Act
 - FinCEN rules for Money Services Businesses (MSBs)
 - OFAC regulations related to economic and trade sanctions
 - FFIEC Examination Manual guidelines for AML compliance

1.2 Purpose

The purpose of this BSA/AML & OFAC Policy is to establish a comprehensive framework for detecting, preventing, and reporting financial crimes, including money laundering, terrorist financing, fraud, and sanctions violations. As a Money Services Business (MSB), ARBIREM, LLC (d/b/a Superstable) is committed to maintaining a risk-based compliance program that aligns with U.S. regulatory requirements and industry best practices.

This policy defines ARBIREM’s AML compliance program, outlining the company’s approach to customer due diligence (CDD), transaction monitoring, suspicious activity reporting, and sanctions screening. It ensures adherence to all applicable U.S. laws and regulations, including the Bank Secrecy Act (BSA), USA PATRIOT Act, FinCEN rules, OFAC regulations, and FFIEC guidelines.

Additionally, this policy assigns clear roles and responsibilities for compliance oversight within ARBIREM, ensuring that employees, agents, and affiliates understand their obligations. By implementing strong AML controls, ARBIREM aims to mitigate financial crime risks, protect its customers, and maintain the integrity of the financial system.

1.3 Applicability

This policy applies to all employees, agents, and affiliates of ARBIREM, LLC. All individuals covered under this policy must strictly adhere to its provisions and comply with all relevant AML and sanctions laws.

1.4 Board Oversight & Compliance Leadership

The Board of Directors of ARBIREM is responsible for reviewing and approving this policy and ensuring adequate resources are allocated for AML compliance.

Additionally, ARBIREM has appointed a dedicated AML Compliance Officer, who is responsible for overseeing the company’s BSA/AML & OFAC program and ensuring ongoing regulatory compliance.


`}
                    </Typography>

                    <Typography id="modal-modal-title" variant="h7" component="h2" gutterBottom>
                        Section 2 : BSA/AML Compliance Program Overview
                    </Typography>

                    <Typography
                        id="modal-modal-description"
                        sx={{ whiteSpace: 'pre-wrap', fontSize: '14px', lineHeight: '1.6' }}
                        component="div"
                    >
                        {`2.1 Overview

ARBIREM, LLC (Superstable) has established a risk-based BSA/AML compliance program designed to detect, prevent, and report money laundering, terrorist financing, fraud, and sanctions violations. This program aligns with all applicable U.S. laws and regulations, including the Bank Secrecy Act (BSA), USA PATRIOT Act, FinCEN rules, OFAC regulations, and FFIEC Examination Manual guidelines. The compliance framework is built on key pillars, including the designation of an AML Compliance Officer, a risk-based approach to customer due diligence, transaction monitoring, independent audits, and ongoing AML training for employees, contractors, and management. The program is designed to evolve as regulatory expectations, business operations, and financial crime risks change

2.2 AML Compliance Officer

ARBIREM has designated an AML Compliance Officer who will be responsible for developing, implementing, and enforcing the company’s BSA/AML & OFAC compliance program. Until a dedicated Compliance Officer is retained, the CEO will assume this role and report directly to the Board of Directors. The AML Compliance Officer will have the authority, resources, and independence necessary to ensure that ARBIREM remains in full compliance with its regulatory obligations.

The AML Compliance Officer’s responsibilities include overseeing customer due diligence (CDD), enhanced due diligence (EDD), transaction monitoring, and suspicious activity reporting (SARs). Additionally, the Compliance Officer will be responsible for conducting AML training, coordinating independent audits, and maintaining communication with regulatory bodies. While some compliance functions may be delegated, ultimate accountability for the effectiveness of the AML program will rest with the AML Compliance Officer.

2.3 Risk-Based Approach to AML Compliance

ARBIREM follows a risk-based approach to AML compliance, ensuring that customers, transactions, and business relationships are assessed based on their potential risk exposure. The company categorizes customers based on various risk factors, including geographical location, transaction volume, and industry type, applying enhanced due diligence (EDD) measures when necessary.

In the early stages of operations, transaction monitoring will be conducted manually to identify potential suspicious activity, with plans to integrate an automated monitoring system as the business scales. The risk-based approach allows ARBIREM to focus compliance resources where they are most needed, strengthening overall AML controls while maintaining operational efficiency.

2.4 Independent Testing & Annual Audits

To ensure the ongoing effectiveness of its AML compliance program, ARBIREM will conduct annual independent audits. These audits will assess the adequacy of AML policies, procedures, and controls, ensuring compliance with regulatory requirements. The results of each audit will be reviewed by the Board of Directors, and any identified deficiencies will be addressed through timely remediation efforts. Independent testing will serve as a critical mechanism for identifying
compliance gaps and improving ARBIREM’s ability to detect and prevent financial crimes.

2.5 AML Training Program

All employees, contractors, and management personnel will be required to undergo AML training to ensure they understand their compliance obligations and can identify potential financial crime risks. Training will be conducted by the AML Compliance Officer or an authorized delegate and will be tailored to employees’ roles and responsibilities. The training program will cover essential topics such as customer due diligence, transaction monitoring, identifying suspicious activity, and reporting obligations. Training sessions will be held on an ongoing basis, with periodic refreshers to address regulatory changes and emerging threats in the financial industry.

2.6 Transaction Monitoring & Suspicious Activity Reporting (SARs)

ARBIREM will initially employ a manual transaction monitoring process, ensuring that all transactions are reviewed for unusual patterns, structuring, or other red flags indicative of suspicious activity. As transaction volume increases, the company will transition to an automated transaction monitoring system to enhance efficiency and accuracy.

When a transaction is flagged as suspicious, it will be escalated to the Board of Directors for review and alignment before determining whether a Suspicious Activity Report (SAR) should be filed with FinCEN. If a SAR filing is required, it will be submitted within the prescribed regulatory timeframe, and all supporting documentation will be maintained for audit and compliance purposes. ARBIREM is committed to maintaining a clear and well-documented escalation and reporting process to ensure compliance with regulatory obligations and prevent financial crime.


`}
                    </Typography>
                    <Typography id="modal-modal-title" variant="h7" component="h2" gutterBottom>
                        Section 3 : Risk Assessment
                    </Typography>

                    <Typography
                        id="modal-modal-description"
                        sx={{ whiteSpace: 'pre-wrap', fontSize: '14px', lineHeight: '1.6' }}
                        component="div"
                    >
                        {`3.1 Overview

ARBIREM, LLC (Superstable) has implemented a risk-based approach to assessing and mitigating money laundering, terrorist financing, and sanctions risks. This risk assessment process is designed to identify, measure, and manage inherent risks associated with the company’s customers, geographic exposure, products, and services. By regularly evaluating these risk factors, ARBIREM ensures that its compliance controls remain effective in preventing financial crimes.

The risk assessment process is an ongoing effort that evolves as the business expands, regulatory expectations change, and emerging threats arise. The findings from each risk assessment directly inform ARBIREM’s AML policies, customer due diligence procedures, and transaction monitoring controls

3.2 Customer Risk Factors

ARBIREM evaluates customer risk based on several key factors, including jurisdiction, industry type, and whether the customer is a Money Services Business (MSB). Customers from high-risk jurisdictions, operating in industries susceptible to financial crime, or engaged in MSB activities are subject to enhanced due diligence (EDD) and additional monitoring. The company applies a risk-scoring model to classify customers as low, medium, or high risk, ensuring appropriate levels of oversight and scrutiny.

3.3 Geographical Risk

Geographical risk is a significant component of ARBIREM’s risk assessment framework. While ARBIREM is a new business, it has a strict policy prohibiting transactions involving sanctioned countries in compliance with OFAC and U.S. Treasury regulations. Additionally, ARBIREM maintains a high-risk country list based on guidance from regulatory bodies, international watchdog organizations, and internal risk assessments. Transactions involving counterparties in high-risk jurisdictions are subject to additional scrutiny and due diligence to mitigate exposure to illicit finance risks.

3.4 Product & Service Risk

ARBIREM’s stablecoin payout service presents inherent AML risks due to its facilitation of cross-border transactions and the potential for high-value payments. The ability to move digital assets quickly across jurisdictions increases the need for robust monitoring, customer due diligence, and transaction oversight. ARBIREM proactively addresses these risks by implementing enhanced due diligence (EDD) measures for high-risk customers, requiring detailed transaction justifications, and conducting thorough counterparty reviews before processing large payouts.

3.5 Ongoing Risk Assessment

ARBIREM will conduct a formal AML risk assessment on an annual basis to ensure continued compliance with regulatory requirements and evolving risk trends. Additionally, the company will perform ad-hoc risk assessments whenever there is a material change to its business model, customer base, or regulatory environment. The results of these assessments will inform updates to ARBIREM’s BSA/AML policies, risk controls, and monitoring procedures.


`}
                    </Typography>

                    <Typography id="modal-modal-title" variant="h7" component="h2" gutterBottom>
                        Section 4 : Customer Identification Program (CIP) & Know Your Customer (KYC)
                    </Typography>

                    <Typography
                        id="modal-modal-description"
                        sx={{ whiteSpace: 'pre-wrap', fontSize: '14px', lineHeight: '1.6' }}
                        component="div"
                    >
                        {`4.1 Overview

ARBIREM, LLC (Superstable) has established a Customer Identification Program (CIP) and Know Your Customer (KYC) procedures to ensure that all commercial customers are properly identified and verified before engaging in transactions. These procedures are designed to
comply with BSA/AML regulations and to mitigate the risk of money laundering, terrorist financing, and other illicit activities.

As ARBIREM exclusively serves commercial entities, the company does not onboard individual customers. Instead, the customer identification process focuses on collecting business formation documents, identifying beneficial owners (UBOs), and reviewing each customer’s internal KYC/AML policies. By implementing a risk-based approach, ARBIREM ensures that higher-risk customers undergo enhanced due diligence (EDD) and ongoing monitoring.

4.2 Business Customer Verification

Before establishing a business relationship, ARBIREM conducts due diligence on each commercial customer to verify its legitimacy and assess potential risks. At a minimum, ARBIREM requires customers to provide:

  - Company registration documents (e.g., articles of incorporation, business licenses).
  - Proof of legal existence (e.g., certificates of good standing).
  - Identification of ultimate beneficial owners (UBOs) holding 25% or more ownership.
  - Government-issued identification for UBOs.
  - The customer’s KYC/AML policy to assess their internal compliance controls.

In addition to document collection, ARBIREM conducts open-source searches to verify the accuracy of customer-provided information and to identify potential adverse media, regulatory actions, or legal issues associated with the business or its owners.

4.3 Beneficial Ownership & Sanctions Screening

ARBIREM follows FinCEN’s Customer Due Diligence (CDD) Rule, which requires the identification and verification of UBOs with 25% or more ownership interest. For each UBO, ARBIREM collects a government-issued ID and conducts open-source background checks to ensure they do not pose a financial crime risk.

As part of its onboarding process, ARBIREM screens all business customers and their UBOs against global sanctions lists before account approval. This includes lists maintained by:

  - The Office of Foreign Assets Control (OFAC)
  - The U.S. Department of State
  - Other relevant international sanctions bodies

If a potential sanctions match is found, ARBIREM conducts further investigation before making a final decision on whether to approve, reject, or escalate the customer for additional review.

4.4 KYC Vendors & Manual Review Process

Currently, ARBIREM performs manual KYC reviews as it evaluates potential third-party KYB/AML vendors for identity verification, sanctions screening, and risk assessments. Until a
vendor is selected, all customer onboarding and due diligence processes are conducted internally using available data sources.

4.5 Ongoing Due Diligence & Periodic Reviews

To ensure that customer risk profiles remain up to date, ARBIREM conducts ongoing due diligence (ODD) on all active accounts. Higher-risk customers undergo a formal review at least annually, with additional reviews triggered by suspicious activity, regulatory changes, or material changes in the customer’s business operations.
Customers flagged as high risk may be subject to additional verification measures, including more frequent reviews, enhanced transaction monitoring, and expanded documentation requirements.


`}
                    </Typography>

                    <Typography id="modal-modal-title" variant="h7" component="h2" gutterBottom>
                        Section 5 : Customer Due Diligence (CDD) & Enhanced Due Diligence (EDD)
                    </Typography>

                    <Typography
                        id="modal-modal-description"
                        sx={{ whiteSpace: 'pre-wrap', fontSize: '14px', lineHeight: '1.6' }}
                        component="div"
                    >
                        {`5.1 Overview

ARBIREM, LLC (Superstable) has implemented a Customer Due Diligence (CDD) and Enhanced Due Diligence (EDD) program to assess the risk level of its commercial customers and ensure compliance with anti-money laundering (AML) regulations. The CDD process is designed to verify the identity of business customers, assess their risk profiles, and establish a baseline for ongoing monitoring. For customers classified as high risk, ARBIREM applies additional scrutiny through its EDD program, requiring further documentation, background checks, and management approval when necessary.

ARBIREM follows a risk-based approach to due diligence, ensuring that higher-risk customers receive increased oversight and ongoing monitoring. This framework allows the company to proactively detect suspicious activity and maintain strong compliance controls.

5.2 Identifying High-Risk Customers

ARBIREM classifies customers as high risk based on a combination of jurisdictional, transactional, industry-related, and behavioral factors. A customer may be designated as high risk if they meet one or more of the following criteria:

  - Jurisdictional Risk: Customers operating in high-risk or non-cooperative jurisdictions, as identified by regulatory bodies or ARBIREM’s internal country risk list.
  - Transaction Volume & Pattern: Customers engaging in large or frequent transactions that exceed expected activity levels.
  - Industry Risk: Businesses operating in industries prone to money laundering or illicit finance, such as virtual asset service providers (VASPs), money services businesses (MSBs), gambling, or cash-intensive operations.
  - Internal Monitoring Flags: Customers who trigger suspicious activity alerts based on transaction patterns, counterparties, or negative media findings.

5.3 Beneficial Ownership & Sanctions Screening

For customers classified as high risk, ARBIREM applies Enhanced Due Diligence (EDD) measures to gain a deeper understanding of their business activities, ownership structure, and potential risks. The EDD process may include:

  - Collection of Additional Documentation: High-risk customers may be required to provide more detailed ownership records, proof of source of funds, or additional business documentation.
  - In-Depth Background Checks: ARBIREM may conduct more extensive adverse media screenings, litigation checks, or financial history reviews on the customer and its beneficial owners.
  - Customer Interviews: When necessary, ARBIREM may conduct direct interviews with key personnel to clarify the nature of the business and its financial activities.
  - Senior Management Approval: In certain cases, onboarding a high-risk customer may require explicit approval from ARBIREM’s senior management before account activation.

5.4 Ongoing Monitoring for High-Risk Customers

Once onboarded, high-risk customers are subject to enhanced transaction monitoring and periodic risk reviews. In addition to the annual due diligence review, ARBIREM applies enhanced monitoring techniques, which may include:

  - Real-Time Transaction Reviews: High-risk customers' transactions will be subject to heightened scrutiny to identify unusual patterns or suspicious activity.
  - Quarterly Risk Reviews: Depending on the customer’s risk level, ARBIREM may review and update risk profiles more frequently than the standard annual cycle.
  - Trigger-Based Reviews: If a high-risk customer exhibits sudden changes in transaction behavior, negative media exposure, or involvement in suspicious activity, ARBIREM will conduct an immediate reassessment of the account.

By applying ongoing enhanced monitoring, ARBIREM ensures that high-risk customers remain compliant and that any emerging risks are addressed in a timely manner


`}
                    </Typography>

                    <Typography id="modal-modal-title" variant="h7" component="h2" gutterBottom>
                        Section 6 : Transaction Monitoring & Suspicious Activity Reporting (SARs)
                    </Typography>

                    <Typography
                        id="modal-modal-description"
                        sx={{ whiteSpace: 'pre-wrap', fontSize: '14px', lineHeight: '1.6' }}
                        component="div"
                    >
                        {`6.1 Overview

ARBIREM, LLC (Superstable) has implemented a transaction monitoring program to detect and prevent potential money laundering, terrorist financing, and other illicit financial activities. The program ensures that all transactions are reviewed for suspicious activity, escalated when necessary, and reported in accordance with regulatory requirements.

As a growing business, ARBIREM is currently conducting manual transaction monitoring, with plans to transition to a threshold-based automated monitoring system as the company scales. This evolving approach allows ARBIREM to identify high-risk transactions efficiently while
ensuring compliance with the Bank Secrecy Act (BSA), USA PATRIOT Act, and FinCEN regulations.

6.2 Identifying High-Risk Customers

Currently, ARBIREM conducts daily transaction reviews to identify potential red flags. Transactions are assessed based on expected customer activity, transaction patterns, and counterparty risk. Over time, the company will shift to a threshold-based monitoring system, where transactions triggering predefined risk parameters will be subject to additional review.

The following red flags automatically trigger a compliance review:

  - Large transactions exceeding normal activity thresholds for a given customer.
  - Rapid movement of funds between multiple parties or jurisdictions without a clear business purpose.
  - Transactions involving high-risk counterparties, such as businesses operating in high-risk jurisdictions or industries associated with financial crime.
  
If a transaction exhibits any of these characteristics, it is immediately flagged for further investigation.

6.3 Escalation & SAR Filing Process

When a transaction is flagged as suspicious, it is initially reviewed by the Compliance Officer. If further investigation is required, the transaction is escalated to the Board of Directors for discussion and alignment before a decision is made on whether to file a Suspicious Activity Report (SAR).

If a SAR is not filed, ARBIREM ensures that:

  - The decision not to file is documented, along with the rationale for the determination. 
  - Supporting documents related to the transaction review are retained for audit purposes.

If a SAR is filed, it is submitted to FinCEN within the required timeframe, and all records related to the investigation are maintained in accordance with regulatory requirements.

6.4 SAR Filing Responsibility

The Compliance Officer or a designated delegate is responsible for preparing and filing SARs. Until an internal SAR review committee is formally established, all SAR filings will be reviewed by the Board of Directors before submission. This process ensures that each SAR is thoroughly vetted and that ARBIREM remains compliant with its regulatory reporting obligations.
By maintaining detailed documentation and an escalation process, ARBIREM ensures that all SAR decisions are well-supported and defensible in regulatory examinations.


`}
                    </Typography>

                    <Typography id="modal-modal-title" variant="h7" component="h2" gutterBottom>
                        Section 7 : Currency Transaction Reports (CTRs) & Recordkeeping
                    </Typography>

                    <Typography
                        id="modal-modal-description"
                        sx={{ whiteSpace: 'pre-wrap', fontSize: '14px', lineHeight: '1.6' }}
                        component="div"
                    >
                        {`7.1 Overview

ARBIREM, LLC (Superstable) has established a recordkeeping and reporting framework to ensure compliance with regulatory requirements under the Bank Secrecy Act (BSA), FinCEN rules, and other applicable regulations. While ARBIREM’s business model does not involve cash transactions, the company maintains detailed records of all financial transactions, customer interactions, and compliance-related activities to support its anti-money laundering (AML) efforts.

7.2 Currency Transaction Reports (CTRs)

ARBIREM does not process cash transactions or any transactions that would require the filing of Currency Transaction Reports (CTRs) for amounts exceeding $10,000. As ARBIREM exclusively facilitates stablecoin payouts, CTR obligations do not apply. However, the company remains committed to monitoring large and unusual transactions as part of its transaction monitoring program to detect potential money laundering and financial crime risks.

7.3 Recordkeeping Requirements

To ensure regulatory compliance and support internal audits, ARBIREM retains all transaction records and compliance documentation for a minimum of five (5) years from the date of the transaction or customer interaction. Records are securely stored in digital format and are accessible for internal audits, regulatory examinations, and law enforcement requests as needed.

7.4 Additional Compliance Recordkeeping

In addition to Suspicious Activity Reports (SARs) and transaction monitoring records, ARBIREM maintains comprehensive documentation related to customer interactions and due diligence efforts. This includes:

  - Customer Correspondence: Records of any significant communications related to account activity, compliance inquiries, or risk assessments.
  - Transaction Justifications: Documentation explaining the purpose and legitimacy of high-value or unusual transactions.
  - Enhanced Due Diligence (EDD) Reviews: Records of high-risk customer assessments, additional documentation collected, and final risk determinations.

By maintaining detailed and well-organized records, ARBIREM ensures that it can demonstrate compliance with regulatory expectations and effectively respond to audit or examination requests.


`}
                    </Typography>

                    <Typography id="modal-modal-title" variant="h7" component="h2" gutterBottom>
                        Section 8 : OFAC Compliance & Sanctions Screening
                    </Typography>

                    <Typography
                        id="modal-modal-description"
                        sx={{ whiteSpace: 'pre-wrap', fontSize: '14px', lineHeight: '1.6' }}
                        component="div"
                    >
                        {`8.1 Overview

ARBIREM, LLC (Superstable) is committed to complying with U.S. sanctions laws and regulations, including those enforced by the Office of Foreign Assets Control (OFAC). As part of its BSA/AML compliance program, ARBIREM has implemented sanctions screening procedures to prevent transactions involving sanctioned individuals, entities, or jurisdictions. These measures help mitigate risks related to money laundering, terrorist financing, and other illicit activities.

ARBIREM’s sanctions compliance program is designed to align with OFAC regulations, FinCEN guidance, and industry best practices. The company conducts sanctions screening at customer onboarding and is in the process of implementing an automated solution for ongoing monitoring of both customers and transactions as the business scales.

8.2 Sanctions Screening Process

Currently, ARBIREM screens all customers and ultimate beneficial owners (UBOs) against sanctions lists at the time of onboarding. This process ensures that no business relationships are established with sanctioned individuals or prohibited entities. Sanctions screening is conducted using the U.S. Treasury’s Specially Designated Nationals (SDN) list and other relevant OFAC lists.

As part of its compliance enhancement efforts, ARBIREM is in the process of selecting a
third-party sanctions screening vendor to enable real-time, ongoing screening of both customers and transactions. This automated system will allow for more efficient detection of potential sanctions violations and ensure compliance with regulatory expectations.

8.3 Handling of Potential OFAC Matches

If a potential sanctions match is detected during screening, the Compliance Officer reviews and analyzes the alert to determine whether it is a true match or a false positive. This process may include:

  - Collecting additional information from the customer to verify identity.
  - Comparing the alert details against official OFAC records to assess validity.
  - Consulting with legal or regulatory experts if further clarification is needed.

If a true match is confirmed at onboarding, the customer application is rejected, and no business relationship is established. If a true match is identified during a transaction, ARBIREM blocks or rejects the transaction in accordance with OFAC regulations. The company is also developing formal procedures for documenting and reporting sanctions-related actions, ensuring transparency and audit readiness.

8.4 Ongoing Compliance & Future Enhancements

Currently, ARBIREM conducts manual sanctions screening using the OFAC SDN list. Since the company is in the early stages of operations and has not yet onboarded customers, the current process is sufficient for regulatory compliance. However, as ARBIREM grows, it plans to implement an automated sanctions screening solution to improve efficiency, ensure real-time monitoring, and enhance compliance oversight.

ARBIREM is also establishing detailed policies and procedures to govern its sanctions screening process, including:

  - Automated alerts for potential sanctions matches.
  - Escalation procedures for Compliance Officer review.
  - Transaction blocking and rejection protocols.
  - Periodic updates to sanctions lists to reflect the latest regulatory changes.

By continuously enhancing its sanctions compliance framework, ARBIREM ensures that it meets regulatory obligations and maintains a strong control environment to prevent sanctions violations.


`}
                    </Typography>

                    <Typography id="modal-modal-title" variant="h7" component="h2" gutterBottom>
                        Section 9 : Independent Testing & Audit
                    </Typography>

                    <Typography
                        id="modal-modal-description"
                        sx={{ whiteSpace: 'pre-wrap', fontSize: '14px', lineHeight: '1.6' }}
                        component="div"
                    >
                        {`9.1 Overview

ARBIREM, LLC (Superstable) is committed to maintaining a robust and effective BSA/AML compliance program through regular independent testing and audits. In accordance with regulatory requirements and best practices, ARBIREM conducts annual independent AML audits to assess the effectiveness of its policies, procedures, and internal controls. These audits ensure that ARBIREM remains compliant with the Bank Secrecy Act (BSA), USA PATRIOT Act, FinCEN regulations, and OFAC requirements while identifying areas for improvement.

The audit process provides an objective evaluation of ARBIREM’s transaction monitoring, suspicious activity reporting, sanctions compliance, customer due diligence (CDD), and internal governance. Findings from the audit are used to strengthen ARBIREM’s AML program and ensure continuous improvement in risk management practices.

9.2 Audit Frequency & Scope

ARBIREM will conduct an independent AML audit annually to evaluate its compliance program and risk management framework. The scope of the audit will include:

  - Transaction Monitoring & SAR Filings: Assessing the effectiveness of ARBIREM’s monitoring program and reviewing past suspicious activity reports (SARs).
  - Sanctions Compliance: Evaluating ARBIREM’s sanctions screening processes to ensure compliance with OFAC and other regulatory requirements.
  - Customer Due Diligence (CDD) & Enhanced Due Diligence (EDD): Reviewing onboarding procedures, beneficial ownership verification, and ongoing monitoring of high-risk customers.
  - Policy & Procedure Compliance: Ensuring that ARBIREM’s internal policies align with regulatory expectations and industry standards.

9.3 Audit Vendor & Reporting Process

To ensure independence and expertise, ARBIREM will engage an external audit firm for its AML compliance testing. The company plans to partner with TheAudit.ai, a specialized compliance audit provider, to conduct these assessments.

Upon completion of the audit, all findings and recommendations will be tracked within TheAudit.ai platform, allowing for real-time monitoring of compliance improvements. The Board of Directors will review all audit findings and oversee the development of a formal corrective action plan to address any identified gaps or weaknesses.

9.4 Remediation & Corrective Actions

If an audit identifies deficiencies in ARBIREM’s AML program, a formal corrective action plan will be implemented to ensure timely remediation. The Chief Compliance Officer (CCO) will be responsible for overseeing corrective actions, ensuring that:

  - Deficiencies are addressed in accordance with regulatory requirements and best practices.
  - Action items are assigned clear deadlines and accountability measures.
  - The Board of Directors monitors remediation efforts to ensure effective resolution.

By maintaining a structured audit process and proactively addressing compliance gaps, ARBIREM ensures that its AML program remains effective, adaptive, and fully aligned with regulatory expectations.


`}
                    </Typography>

                    <Typography id="modal-modal-title" variant="h7" component="h2" gutterBottom>
                        Section 10 : AML Training Program
                    </Typography>

                    <Typography
                        id="modal-modal-description"
                        sx={{ whiteSpace: 'pre-wrap', fontSize: '14px', lineHeight: '1.6' }}
                        component="div"
                    >
                        {`10.1 Overview

ARBIREM, LLC (Superstable) recognizes that ongoing AML training is essential to maintaining a strong compliance culture and ensuring that employees, management, and contractors understand their responsibilities under applicable BSA/AML and OFAC regulations. The company has developed an internal AML training program designed to provide personnel with the necessary knowledge to identify, prevent, and report financial crimes effectively.

Training is structured to cover core compliance topics and will be tailored to specific job roles as the organization grows. This ensures that employees involved in customer due diligence (CDD), transaction monitoring, and suspicious activity reporting (SARs) receive the appropriate level of instruction relevant to their responsibilities.

10.2 Training Requirements

AML training is mandatory for all employees, management, and contractors engaged with ARBIREM. The training program ensures that all personnel are equipped with the knowledge required to comply with internal policies, regulatory requirements, and industry best practices.

Initially, all employees will receive general AML training, but as ARBIREM expands and its processes become more complex, the company will implement role-specific training modules to ensure that compliance responsibilities are effectively addressed at different levels of the organization.

10.3 Training Frequency & Delivery

AML training will be conducted annually to ensure that all employees remain up to date on regulatory changes and emerging financial crime risks. Additionally, new employees and contractors will be required to complete AML training as part of the onboarding process before assuming any job functions related to compliance.

To ensure accessibility and flexibility, all training sessions will be delivered online, allowing employees to complete training modules at their own pace while ensuring full participation across all business units.

10.4 Remediation & Corrective Actions

ARBIREM’s AML training program will focus on the key compliance topics required for an effective risk management framework. Core topics covered in the training will include:

  - Introduction to the Bank Secrecy Act (BSA) and Anti-Money Laundering (AML) Regulations.
  - Suspicious Activity Report (SAR) Filing Procedures and Red Flags
  - Office of Foreign Assets Control (OFAC) Compliance & Sanctions Screening
  - Customer Due Diligence (CDD) & Enhanced Due Diligence (EDD) Best Practices
  - Transaction Monitoring and Identifying Unusual Activity
  - Compliance Oversight & Reporting Obligations

ARBIREM will continuously update its training materials to incorporate new regulatory requirements, emerging risks, and lessons learned from internal audits or enforcement actions within the industry.

10.5 Compliance Tracking & Recordkeeping

To ensure regulatory compliance, ARBIREM will track and document all AML training completion records. Employees will be required to certify their participation in the training, and records will be retained for audit and regulatory review purposes.

By implementing a structured AML training program, ARBIREM ensures that its personnel remain informed, engaged, and prepared to identify and mitigate financial crime risks in alignment with BSA/AML and OFAC compliance obligations.

`}
                    </Typography>

                    <Typography id="modal-modal-title" variant="h7" component="h2" gutterBottom>
                        Section 11 : Roles & Responsibilities of Key Personnel
                    </Typography>

                    <Typography
                        id="modal-modal-description"
                        sx={{ whiteSpace: 'pre-wrap', fontSize: '14px', lineHeight: '1.6' }}
                        component="div"
                    >
                        {`11.1 Overview

ARBIREM, LLC (Superstable) has established a compliance governance structure that ensures oversight of its BSA/AML & OFAC compliance program at all levels of the organization.
Compliance responsibilities are assigned based on a three-lines-of-defense model, where operational teams, compliance personnel, and the Board of Directors collectively ensure adherence to regulatory requirements.

Currently, ARBIREM’s compliance framework is led by the AML Compliance Officer, with plans to expand the team by adding a Compliance Analyst to assist with monitoring and reporting efforts. The Board of Directors plays an active role in AML oversight, particularly in policy approval, SAR reviews, and granting exceptions to policies. As the company grows, ARBIREM aims to establish a Risk Committee to further enhance compliance governance.

11.2 Compliance Team Structure

The compliance function at ARBIREM is designed to follow a first-line and second-line-of-defense model:

  - First Line of Defense: Operational teams responsible for conducting initial due diligence and monitoring transactions for unusual activity.
  - Second Line of Defense: The Compliance Team, led by the AML Compliance Officer, which enforces policies, investigates flagged transactions, and files regulatory reports.

Initially, the compliance function will be centralized, with the AML Compliance Officer overseeing all compliance-related duties. As the business scales, ARBIREM plans to introduce specialized roles, such as risk analysts and investigators, to further strengthen its compliance program.

11.3 Responsibilities of the AML Compliance Officer

The AML Compliance Officer is responsible for the day-to-day oversight of ARBIREM’s BSA/AML & OFAC program and ensuring adherence to applicable regulations. Key responsibilities include:

  - Developing, implementing, and enforcing AML policies and procedures.
  - Conducting customer due diligence (CDD) and enhanced due diligence (EDD).
  - Reviewing transaction monitoring alerts and escalating suspicious activity.
  - Preparing and filing Suspicious Activity Reports (SARs).
  - Providing AML training to employees, management, and contractors.
  - Coordinating with external auditors and regulatory authorities.
  - Tracking compliance findings and corrective actions.

The Compliance Analyst (once hired) will support the Compliance Officer by assisting in KYC reviews, transaction monitoring, and sanctions screening.

11.4 Board & Senior Management Oversight

The Board of Directors is responsible for providing strategic oversight of the AML program, ensuring that ARBIREM maintains a strong compliance culture. The Board’s key responsibilities include:

  - Approving BSA/AML & OFAC policies and ensuring alignment with regulatory requirements.
  - Reviewing and approving Suspicious Activity Reports (SARs) before submission.
  - Granting exceptions to policies when necessary, based on risk assessments.
  - Overseeing independent audits and compliance testing.

Currently, there is no separate Risk Committee or senior management-level compliance oversight. However, ARBIREM’s long-term goal is to establish a dedicated Risk Committee to manage compliance risks more effectively as the company scales.

11.5 Compliance Tracking & Recordkeeping

At this time, non-compliance personnel such as customer support and finance teams do not have direct compliance-related duties. However, as ARBIREM grows, frontline staff may be trained to identify and escalate potential red flags, ensuring that compliance risks are managed proactively.

By clearly defining compliance roles and responsibilities, ARBIREM ensures that regulatory requirements are met, risks are managed effectively, and the compliance function evolves in line with the company’s growth.

`}
                    </Typography>

                    <Typography id="modal-modal-title" variant="h7" component="h2" gutterBottom>
                        Section 12 : Policy Updates & Board Approval
                    </Typography>

                    <Typography
                        id="modal-modal-description"
                        sx={{ whiteSpace: 'pre-wrap', fontSize: '14px', lineHeight: '1.6' }}
                        component="div"
                    >
                        {`12.1 Overview

ARBIREM, LLC (Superstable) recognizes the need for a dynamic and adaptable compliance program that evolves in response to regulatory changes, business growth, and emerging financial crime risks. To ensure continued compliance with BSA/AML & OFAC regulations, ARBIREM will conduct regular reviews and updates of this policy and all associated procedures.

12.2 Policy Review & Updates

The BSA/AML & OFAC Policy will be formally reviewed and updated on an annual basis to reflect any changes in regulatory requirements, risk assessments, or operational procedures. In addition to the annual review, updates may be made on an as-needed basis in response to:

  - Changes in federal, state, or international AML and sanctions regulations.
  - Regulatory guidance from FinCEN, OFAC, or other governing bodies.
  - Findings from independent audits, internal compliance reviews, or regulatory examinations.
  - Material changes in ARBIREM’s business model, services, or customer risk profile.

12.3 Board Approval Process

Policy updates will be initially reviewed and implemented by the Chief Compliance Officer (CCO). The CCO will then present updates to the Board of Directors for review and approval as necessary, particularly when changes involve new regulatory obligations, material risk adjustments, or significant operational shifts.

For minor administrative changes, the CCO may update the policy without requiring immediate Board approval but will ensure that all updates are documented and presented to the Board during the next scheduled compliance review meeting.

12.4 Recordkeeping & Version Control

To maintain transparency and regulatory compliance, ARBIREM will document all policy changes and implement version control to track updates over time. The company will maintain:

  - A record of all policy revisions, including dates of updates and key changes.
  - Board meeting minutes or compliance reports reflecting policy approvals.
  - Archived versions of previous policies for historical reference.

By ensuring regular policy updates, formal approval procedures, and clear recordkeeping, ARBIREM maintains a strong governance framework that supports its commitment to compliance and risk management.


`}
                    </Typography>

                </Box>
            </Modal>

            <div className="max-w-6xl mx-auto flex flex-col md:flex-row justify-between items-center">
                <div className="flex flex-col items-center md:items-start">
                    <img src={logo} alt="SuperStable Logo" className="w-40" />
                    <div className="flex gap-3 mt-2">
                        <Link to={"https://x.com/superstableio"} target="_blank">
                            <img
                                src={twitter}
                                alt="Twitter"
                                className="w-5 h-5 cursor-pointer"
                            />
                        </Link>
                        <Link
                            to={"https://www.linkedin.com/company/superstable/posts/?feedView=all"}
                            target="_blank"
                        >
                            <img
                                src={linkedin}
                                alt="LinkedIn"
                                className="w-5 h-5 cursor-pointer"
                            />
                        </Link>
                    </div>
                </div>

                <div className="mt-6 md:mt-0">
                    <h3 className="text-black font-helveticanow">Get in Touch</h3>
                    <div className="flex mt-2">
                        <input
                            type="email"
                            placeholder="Enter your email"
                            className="px-3 py-2 border border-gray-300 rounded-l-md focus:outline-none focus:ring-2 focus:ring-green-400 w-60 font-helveticanow"
                        />
                        <button className="px-4 py-2 bg-green-500 text-white rounded-r-md hover:bg-green-600">
                            Subscribe
                        </button>
                    </div>
                </div>
            </div>

            <div className="border-t border-t-gray-300 mt-6 pt-4 flex flex-col md:flex-row justify-between items-center text-sm text-gray-500 max-w-6xl mx-auto font-intertight">
                <p>© 2025 SuperStable. All rights reserved.</p>
                <div className="flex gap-4">
                    <a href="/" className="hover:text-black">
                        Terms & Conditions
                    </a>

                    <div onClick={handleOpen} className="hover:text-black cursor-pointer">
                        KYC/AML Policy
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
