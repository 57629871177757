import network from "../../assets/imgs/network.svg";
import { countries } from "../../utils/utils";

const Info = () => {
    return (
        <main className="w-full flex flex-col items-center justify-center gap-5 md:gap-5 lg:mx-6 xl:mx-10 my-10 pt-10 bg-[#f5fbf7] pb-20">
            <h1 className="text-center md:text-start text-3xl lg:text-5xl font-intertightbold my-5">
                What is SuperStable?
            </h1>

            <p className="w-3/4 md:w-1/2 text-center text-sm md:text-lg font-helveticanow">
                SuperStable is revolutionizing cross-border payments by enabling{" "}
                <b>fast, low-cost,</b> and <b>24/7</b> stablecoin payouts across
                emerging markets.
            </p>

            <section className="my-7 flex flex-col items-center gap-2">
                <p className="font-intertight font-semibold text-[#41D87E]">
                    Markets We Cover
                </p>

                <div className="flex justify-center items-center gap-4 flex-wrap font-helveticanow font-semibold">
                    {countries.map((country) => (
                        <div className="flex gap-2 justify-center items-center rounded-4xl shadow-sm px-3 py-2">
                            <img src={country.logo} alt="india" />
                            <p>{country.countryName}</p>
                        </div>
                    ))}
                </div>
            </section>

            <div className="mt-6 md:mt-2 w-full max-w-5xl flex justify-center px-4">
                <img
                    src={network}
                    alt="SuperStable Network"
                    className="w-full h-auto"
                />
            </div>
        </main>
    );
};

export default Info;
