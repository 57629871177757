import HeroDEXWidget from "./HeroDEXWidget";
import superfastlogo from "../../assets/imgs/energy.svg";
import supercheaplogo from "../../assets/imgs/low-price.svg";
import superstable from "../../assets/imgs/superstable.svg";
import rightArrow from "../../assets/imgs/arrow_forward_ios.svg";
import { useAuth } from "../../context/AuthContext";
import { Link } from "react-router-dom";

const Hero = () => {
    const { setShowAuth } = useAuth();

    return (
        <main className="max-w-[70rem] flex flex-col lg:flex-row items-center justify-center gap-10 md:gap-5 lg:mx-6 xl:mx-16 my-5 pb-20 ">

            <section className="min-w-1/2 mx-6 flex flex-col items-center md:items-start gap-4">
                <h1 className="md:w-[85%] text-center md:text-start text-3xl lg:text-5xl font-intertightbold">
                    The Future of Cross-Border Stablecoin Payments
                </h1>

                <section className="flex items-center gap-2 m-2 my-6 md:m-3">
                    <div className="flex bg-white rounded-xl p-2 m-1 gap-2 border border-slate-100/55">
                        <img src={superfastlogo} alt="superfastlogo" />
                        <p className="font-helveticanow  text-sm">Fast</p>
                    </div>
                    <div className="flex bg-white rounded-xl p-2 m-1 gap-2 border border-slate-100/55">
                        <img src={supercheaplogo} alt="supercheaplogo" />
                        <p className="font-helveticanow text-sm">Economical</p>
                    </div>
                    <div className="flex bg-white rounded-xl p-2 m-1 gap-2 border border-slate-100/55">
                        <img src={superstable} alt="superstable" />
                        <p className="font-helveticanow  text-sm">Compliant</p>
                    </div>
                </section>

                <section className="m-2 mx-4 flex items-center gap-4">
                    <button onClick={() => setShowAuth(true)} className="rounded-3xl bg-black text-white  cursor-pointer hover:scale-105 transition-all ease-in-out p-3 font-helveticanow flex">
                        <p>Send Now</p>
                        <img src={rightArrow} alt="right" />
                    </button>
                    <Link to={"https://form.typeform.com/to/lzmeDErF"} target="_blank">
                        <button className="rounded-3xl bg-black text-white  cursor-pointer hover:scale-105 transition-all ease-in-out p-3 font-helveticanow flex">
                            <p>Contact Us</p>
                            <img src={rightArrow} alt="right" />
                        </button>
                    </Link>
                </section>
            </section>

            <section className="min-w-1/2">
                <HeroDEXWidget />
            </section>
        </main>
    );
};

export default Hero;
