import React, { useEffect } from 'react';
import AuthForm from './AuthForm';
import text from "../assets/imgs/superstable_text.svg";
import logo from "../assets/imgs/superstable_logo.svg";
import Hero from './landing-page-components/Hero';
import Info from './landing-page-components/Info';
import Choose from './landing-page-components/Choose';
import WhoUses from './landing-page-components/WhoUses';
import Payout from './landing-page-components/Payout';
import Upcoming from './landing-page-components/Upcoming';
import Footer from './landing-page-components/Footer';
import { useAuth } from '../context/AuthContext';

const API_BASE_URL = 'https://superstablecontainerapp.politecoast-3c06f7f1.eastus.azurecontainerapps.io/health';


const IntegratedLanding = () => {
  // const [showAuth, setShowAuth] = useState(false);
  const { showAuth, setShowAuth } = useAuth();

  useEffect(() => {
    const warmUpBackend = async () => {
      try {
        await fetch(API_BASE_URL);
      } catch (error) {
        // Silent fail - no need to handle errors since this is just for warmup
      }
    };

    warmUpBackend();
  }, []);

  return (
    // Navbar
    <div className="flex flex-col items-center m-auto">
      <nav className="h-10 w-full xl:px-4 items-center flex justify-between py-8 my-3 rounded-xl shadow-md max-w-7xl mx-auto">
        <div className="mx-3 flex items-center gap-3">
          <a href="/">
            <img className="" src={logo} alt="logo" />
          </a>
          <a href="/">
            <img className="w-44" src={text} alt="logo" />
          </a>
        </div>

        <button
          onClick={() => setShowAuth(true)}
          className="bg-primary px-4 py-2 cursor-pointer hover:bg-primary/90 capitalize  rounded-xl bg text-white text-sm bg-black leading-4 mx-3 font-helveticanow hover:scale-105 transition-all ease-in-out"
        >
          Sign In
        </button>
      </nav>

      {/* Hero section */}
      <Hero />


      {/* Info section */}
      <Info />

      {/* Why Choose section */}
      <Choose />

      {/* Who uses section */}
      <WhoUses />

      {/* Payout section */}
      <Payout />

      {/* Upcoming section */}
      <Upcoming />

      {/* Footer section */}
      <Footer />


      {/* Auth Modal - More compact layout */}
      {showAuth && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity z-50 flex items-center justify-center sm:p-6">
          <div
            className="relative w-full max-w-xl transform transition-all animate-modalSlide rounded-xl "
            onClick={(e) => e.stopPropagation()}
          >
            {/* <div className="relative right-2 top-2 z-10">
              <button
                onClick={() => setShowAuth(false)}
                className="p-2 rounded-full hover:bg-gray-100 text-gray-500 hover:text-gray-700 transition-colors"
                aria-label="Close modal"
              >
                <X className="h-5 w-5" />
              </button>
            </div> */}
            {/* Apply more compact padding to AuthForm */}
            <div className="p-4 sm:p-6">
              <AuthForm />
            </div>
          </div>
        </div>
      )}

      <style jsx>{`
        @keyframes fadeIn {
          from { opacity: 0; }
          to { opacity: 1; }
        }

        @keyframes slideDown {
          from { transform: translateY(-20px); opacity: 0; }
          to { transform: translateY(0); opacity: 1; }
        }

        @keyframes slideRight {
          from { transform: translateX(-20px); opacity: 0; }
          to { transform: translateX(0); opacity: 1; }
        }

        @keyframes modalSlide {
          from { transform: scale(0.95); opacity: 0; }
          to { transform: scale(1); opacity: 1; }
        }

        .animate-fadeIn {
          animation: fadeIn 0.5s ease-out;
        }

        .animate-slideDown {
          animation: slideDown 0.5s ease-out;
        }

        .animate-slideRight {
          animation: slideRight 0.5s ease-out;
        }

        .animate-modalSlide {
          animation: modalSlide 0.3s ease-out;
        }
      `}</style>
    </div>
  );
};

export default IntegratedLanding;