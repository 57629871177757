import React, { useState, useEffect, useCallback } from 'react';
import { useAuth } from '../context/AuthContext';
import { Wallet, QrCode, X, Copy,  ExternalLink, Check, UserPlus, PiggyBank, Loader2, List, SendHorizontal, Plus } from 'lucide-react';
import { QRCodeSVG } from 'qrcode.react';


const Card = ({ children, className = '' }) => (
  <div className={`bg-white rounded-lg shadow-md ${className}`}>
    {children}
  </div>
);

const CardHeader = ({ children }) => (
  <div className="px-6 py-4 border-b border-gray-200">
    {children}
  </div>
);

const CardTitle = ({ children }) => (
  <h3 className="text-lg font-semibold text-gray-900">
    {children}
  </h3>
);

const CardContent = ({ children, className = '' }) => (
  <div className={`px-6 py-4 ${className}`}>
    {children}
  </div>
);

// Balance Component
const API_BASE_URL = 'https://superstablecontainerapp.politecoast-3c06f7f1.eastus.azurecontainerapps.io' //'http://superstablecontainerapp.politecoast-3c06f7f1.eastus.azurecontainerapps.io'; // Match your FastAPI server

const individualKycTypes = ['Passport', 'Driving License', 'PAN Card', 'SSN', 'ITIN'];
const businessKycTypes = ['Tax ID'];


const AddUSDBalanceButton = ({ onSelect }) => {
  return (
    <button
      onClick={() => onSelect('wallets')}
      className="w-full inline-flex items-center justify-center px-4 py-3 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors"
    >
      <Plus className="w-5 h-5 mr-2" />
      Add USD Balance
    </button>
  );
};



const BalanceContent = ({ onSelect }) => {
  const [balanceData, setBalanceData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchBalance = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('Authentication token not found. Please login again.');
      }
      const response = await fetch(`${API_BASE_URL}/api/check_balance`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        credentials: 'include',
        mode: 'cors',
      });

      if (response.status === 401) {
        throw new Error('Session expired. Please login again.');
      }
      if (response.status === 403) {
        throw new Error('Access denied. Please check your permissions.');
      }
      if (!response.ok) {
        throw new Error(`Server error: ${response.status}`);
      }
      const data = await response.json();
      setBalanceData(data);  // Expecting data.data.total_amount, etc.
      setError(null);
    } catch (err) {
      console.error('Balance fetch error:', err);
      setError(err.message);
      setBalanceData(null);
      if (err.message.includes('login') || err.message.includes('token') || err.message.includes('session')) {
        window.location.href = '/login';
        return;
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBalance();
  }, []);

  if (loading) {
    return (
      <div className="p-6 flex items-center justify-center min-h-[400px]">
        <div className="text-center">
          <Loader2 className="w-8 h-8 animate-spin text-blue-600 mx-auto mb-2" />
          <p className="text-sm text-gray-500">Loading balance...</p>
        </div>
      </div>
    );
  }
  if (error) {
    return (
      <div className="p-6">
        <div className="bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded relative">
          <h3 className="font-semibold mb-1">Error Loading Balance</h3>
          <p className="text-sm">{error}</p>
          {!(error.includes('login') || error.includes('token') || error.includes('session')) && (
            <button
              onClick={fetchBalance}
              className="mt-2 text-sm bg-red-100 hover:bg-red-200 text-red-800 px-3 py-1 rounded inline-flex items-center"
            >
              <Loader2 className="w-4 h-4 mr-1" />
              Retry
            </button>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="p-6 space-y-8">
      {/* Total Balance Section */}
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-semibold">Total Balance</h2>
        <button
          onClick={fetchBalance}
          className="text-sm text-gray-500 hover:text-gray-700 flex items-center gap-1"
        >
          <Loader2 className="w-4 h-4" />
          Refresh
        </button>
      </div>
      <Card>
        <CardHeader>
          <CardTitle>Total Balance</CardTitle>
        </CardHeader>
        <CardContent>
          <p className="text-4xl font-bold text-blue-600">
            ${Number(balanceData?.amount || 0).toFixed(2)}
          </p>
        </CardContent>
      </Card>

      {/* Wallet Balances Section */}
      <h2 className="text-2xl font-semibold mt-8">Wallet Balances</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <Card>
          <CardHeader>
            <CardTitle>USDC Balance</CardTitle>
          </CardHeader>
          <CardContent>
            <p className="text-4xl font-bold text-blue-600">
              ${Number(balanceData?.usdc || 0).toFixed(2)}
            </p>
          </CardContent>
        </Card>
        <Card>
          <CardHeader>
            <CardTitle>USDT Balance</CardTitle>
          </CardHeader>
          <CardContent>
            <p className="text-4xl font-bold text-blue-600">
              ${Number(balanceData?.usdt || 0).toFixed(2)}
            </p>
          </CardContent>
        </Card>
      </div>
      <AddUSDBalanceButton onSelect={onSelect} />
    </div>
  );
};





const WalletsContent = () => {
  const [addresses, setAddresses] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [copiedAddress, setCopiedAddress] = useState(null);
  const [showQR, setShowQR] = useState(null);

  const fetchAddresses = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem('token');

      if (!token) {
        throw new Error('Authentication token not found. Please login again.');
      }

      const response = await fetch(`${API_BASE_URL}/api/get_addresses`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        credentials: 'include',
        mode: 'cors',
      });

      if (response.status === 401) {
        throw new Error('Session expired. Please login again.');
      }

      if (response.status === 403) {
        throw new Error('Access denied. Please check your permissions.');
      }

      if (!response.ok) {
        throw new Error(`Server error: ${response.status}`);
      }

      const data = await response.json();
      setAddresses(data.data);
      setError(null);
    } catch (err) {
      console.error('Address fetch error:', err);
      setError(err.message);
      setAddresses(null);

      if (err.message.includes('login') || err.message.includes('token') || err.message.includes('session')) {
        window.location.href = '/login';
        return;
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAddresses();
  }, []);

  const copyToClipboard = async (address, type) => {
    try {
      await navigator.clipboard.writeText(address);
      setCopiedAddress(type);
      setTimeout(() => setCopiedAddress(null), 2000);
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  };

  const getExplorerLink = (address, type) => {
    if (type === 'eth') {
      return `https://etherscan.io/address/${address}`;
    } else if (type === 'tron') {
      return `https://tronscan.org/#/address/${address}`;
    }
    return '#';
  };

  if (loading) {
    return (
      <div className="p-6 flex items-center justify-center min-h-[400px]">
        <div className="text-center">
          <Loader2 className="w-8 h-8 animate-spin text-blue-600 mx-auto mb-2" />
          <p className="text-sm text-gray-500">Loading addresses...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-6">
        <div className="bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded relative">
          <h3 className="font-semibold mb-1">Error Loading Addresses</h3>
          <p className="text-sm">{error}</p>
          {!(error.includes('login') || error.includes('token') || error.includes('session')) && (
            <button
              onClick={fetchAddresses}
              className="mt-2 text-sm bg-red-100 hover:bg-red-200 text-red-800 px-3 py-1 rounded inline-flex items-center"
            >
              <Loader2 className="w-4 h-4 mr-1" />
              Retry
            </button>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="p-6 space-y-6">
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-semibold">Deposit Addresses</h2>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* ETH Wallet Card */}
        {addresses?.eth_address && (
          <Card>
            <CardHeader>
              <CardTitle className="flex items-center gap-2">
                <img src="/eth-logo.svg" alt="ETH" className="w-6 h-6" />
                ETH Network Address
              </CardTitle>
            </CardHeader>
            <CardContent>
              <div className="bg-gray-50 p-4 rounded-lg">
                <p className="font-mono text-sm break-all">{addresses.eth_address}</p>
                <div className="mt-4 flex gap-2">
                  <button
                    onClick={() => copyToClipboard(addresses.eth_address, 'eth')}
                    className="text-sm px-3 py-1.5 bg-white border rounded-md hover:bg-gray-50 flex items-center gap-1"
                  >
                    {copiedAddress === 'eth' ? (
                      <Check className="w-4 h-4 text-green-500" />
                    ) : (
                      <Copy className="w-4 h-4" />
                    )}
                    {copiedAddress === 'eth' ? 'Copied!' : 'Copy'}
                  </button>
                  <button
                    onClick={() => setShowQR('eth')}
                    className="text-sm px-3 py-1.5 bg-white border rounded-md hover:bg-gray-50 flex items-center gap-1"
                  >
                    <QrCode className="w-4 h-4" />
                    QR Code
                  </button>
                  <a
                    href={getExplorerLink(addresses.eth_address, 'eth')}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-sm px-3 py-1.5 bg-white border rounded-md hover:bg-gray-50 flex items-center gap-1"
                  >
                    <ExternalLink className="w-4 h-4" />
                    View
                  </a>
                </div>
              </div>
            </CardContent>
          </Card>
        )}

        {/* TRON Wallet Card */}
        {addresses?.tron_address && (
          <Card>
            <CardHeader>
              <CardTitle className="flex items-center gap-2">
                <img src="/tron-logo.svg" alt="TRON" className="w-6 h-6" />
                TRON Network Address
              </CardTitle>
            </CardHeader>
            <CardContent>
              <div className="bg-gray-50 p-4 rounded-lg">
                <p className="font-mono text-sm break-all">{addresses.tron_address}</p>
                <div className="mt-4 flex gap-2">
                  <button
                    onClick={() => copyToClipboard(addresses.tron_address, 'tron')}
                    className="text-sm px-3 py-1.5 bg-white border rounded-md hover:bg-gray-50 flex items-center gap-1"
                  >
                    {copiedAddress === 'tron' ? (
                      <Check className="w-4 h-4 text-green-500" />
                    ) : (
                      <Copy className="w-4 h-4" />
                    )}
                    {copiedAddress === 'tron' ? 'Copied!' : 'Copy'}
                  </button>
                  <button
                    onClick={() => setShowQR('tron')}
                    className="text-sm px-3 py-1.5 bg-white border rounded-md hover:bg-gray-50 flex items-center gap-1"
                  >
                    <QrCode className="w-4 h-4" />
                    QR Code
                  </button>
                  <a
                    href={getExplorerLink(addresses.tron_address, 'tron')}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-sm px-3 py-1.5 bg-white border rounded-md hover:bg-gray-50 flex items-center gap-1"
                  >
                    <ExternalLink className="w-4 h-4" />
                    View
                  </a>
                </div>
              </div>
            </CardContent>
          </Card>
        )}
      </div>

      {/* Warning Message */}
      <div className="bg-yellow-50 border border-yellow-200 text-yellow-800 px-4 py-3 rounded">
        <p className="text-sm">
          <strong>Important:</strong> Only send USDC to the ETH address and USDT to the TRON address.
          Sending other tokens may result in permanent loss of funds.
        </p>
      </div>

      {/* QR Code Modal */}
      {showQR && addresses && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg max-w-sm w-full mx-4">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-semibold">
                {showQR === 'eth' ? 'ETH' : 'TRON'} Address QR Code
              </h3>
              <button
                onClick={() => setShowQR(null)}
                className="text-gray-500 hover:text-gray-700"
              >
                <X className="w-5 h-5" />
              </button>
            </div>
            <div className="bg-white p-4 rounded-lg flex justify-center">
              <QRCodeSVG
                value={showQR === 'eth' ? addresses.eth_address : addresses.tron_address}
                size={200}
                level="H"
                includeMargin={true}
                className="w-full max-w-[200px]"
              />
            </div>
            <p className="mt-4 text-sm text-gray-600 break-all font-mono">
              {showQR === 'eth' ? addresses.eth_address : addresses.tron_address}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};



const AddSenderContent = () => {
  // Toggle sender type: "individual" or "business"
  const [senderType, setSenderType] = useState('individual');

  // Removed kyc_document from state
  const [formData, setFormData] = useState({
    beneficiary_name: '', // will serve as "sender_name" for individual and "business_name" for business
    dob: '', // only for individual sender
    current_address: '', // "Current Address" for individual; "Company Address" for business
    nationality: '', // "Nationality" for individual; "Country of Incorporation" for business
    kyc_type: '', // For individual: KYC type; for business: KYB type
    kyc_number: '', // For individual: KYC number; for business: KYB number
  });

  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const validateForm = () => {
    if (!formData.beneficiary_name.trim()) {
      setError(senderType === 'individual' ? 'Sender name is required' : 'Business name is required');
      return false;
    }
    if (senderType === 'individual' && !formData.dob) {
      setError('Date of birth is required');
      return false;
    }
    if (!formData.current_address.trim()) {
      setError(senderType === 'individual' ? 'Current address is required' : 'Company address is required');
      return false;
    }
    if (!formData.nationality) {
      setError(
        senderType === 'individual'
          ? 'Nationality is required'
          : 'Country of incorporation is required'
      );
      return false;
    }
    if (!formData.kyc_type) {
      setError(senderType === 'individual' ? 'KYC type is required' : 'KYB type is required');
      return false;
    }
    if (!formData.kyc_number.trim()) {
      setError(senderType === 'individual' ? 'KYC number is required' : 'KYB number is required');
      return false;
    }
    return true;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    setError('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    if (!validateForm()) return;

    setIsSubmitting(true);
    try {
      const formDataToSend = new FormData();
      let endpoint = '';

      if (senderType === 'individual') {
        // Append fields as expected by /senders/individual
        formDataToSend.append('sender_name', formData.beneficiary_name.trim());
        // Format dob as YYYY-MM-DD
        formDataToSend.append('dob', new Date(formData.dob).toISOString().split('T')[0]);
        formDataToSend.append('current_address', formData.current_address.trim());
        formDataToSend.append('nationality', formData.nationality);
        formDataToSend.append('kyc_type', formData.kyc_type);
        formDataToSend.append('kyc_number', formData.kyc_number.trim());
        endpoint = `${API_BASE_URL}/senders/individual`;
      } else {
        // Business sender: map business_name, company_address, country_of_incorporation, kyb_type, kyb_number
        formDataToSend.append('business_name', formData.beneficiary_name.trim());
        formDataToSend.append('company_address', formData.current_address.trim());
        formDataToSend.append('country_of_incorporation', formData.nationality);
        formDataToSend.append('kyb_type', formData.kyc_type);
        formDataToSend.append('kyb_number', formData.kyc_number.trim());
        endpoint = `${API_BASE_URL}/senders/business`;
      }

      const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
          // Do not set 'Content-Type'; let the browser handle the multipart boundary.
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: formDataToSend,
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error('Failed to add sender');
      }

      // Clear form fields on success.
      setFormData({
        beneficiary_name: '',
        dob: '',
        current_address: '',
        nationality: '',
        kyc_type: '',
        kyc_number: '',
      });
      alert('Sender added successfully!');
    } catch (err) {
      setError(err.message || 'Failed to add sender. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="p-6">
      <h2 className="text-2xl font-semibold mb-6">Add Sender</h2>

      {/* Toggle Sender Type */}
      <div className="mb-6">
        <label className="mr-4">
          <input
            type="radio"
            name="senderType"
            value="individual"
            checked={senderType === 'individual'}
            onChange={() => setSenderType('individual')}
            className="mr-1"
          />
          Individual
        </label>
        <label>
          <input
            type="radio"
            name="senderType"
            value="business"
            checked={senderType === 'business'}
            onChange={() => setSenderType('business')}
            className="mr-1"
          />
          Business
        </label>
      </div>

      <Card>
        <div className="p-6">
          <form onSubmit={handleSubmit} className="space-y-6">
            {error && (
              <div className="p-4 mb-4 text-sm text-red-800 bg-red-100 rounded-lg">
                {error}
              </div>
            )}

            <div className="space-y-6">
              <h3 className="text-lg font-medium">
                {senderType === 'individual' ? 'Personal Information' : 'Business Information'}
              </h3>

              {/* Sender/Business Name */}
              <div>
                <label htmlFor="beneficiary_name" className="block text-sm font-medium text-gray-700 mb-2">
                  {senderType === 'individual' ? 'Sender Name *' : 'Business Name *'}
                </label>
                <input
                  id="beneficiary_name"
                  name="beneficiary_name"
                  type="text"
                  value={formData.beneficiary_name}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder={
                    senderType === 'individual' ? 'Enter full name' : 'Enter business name'
                  }
                />
              </div>

              {/* Date of Birth: only for individual */}
              {senderType === 'individual' && (
                <div>
                  <label htmlFor="dob" className="block text-sm font-medium text-gray-700 mb-2">
                    Date of Birth *
                  </label>
                  <input
                    id="dob"
                    name="dob"
                    type="date"
                    value={formData.dob}
                    onChange={handleInputChange}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                </div>
              )}

              {/* Address: label depends on sender type */}
              <div>
                <label htmlFor="current_address" className="block text-sm font-medium text-gray-700 mb-2">
                  {senderType === 'individual' ? 'Current Address *' : 'Company Address *'}
                </label>
                <textarea
                  id="current_address"
                  name="current_address"
                  value={formData.current_address}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder={
                    senderType === 'individual'
                      ? 'Enter current address'
                      : 'Enter company address'
                  }
                  rows={3}
                />
              </div>

              {/* Nationality / Country of Incorporation */}
              <div>
                <label htmlFor="nationality" className="block text-sm font-medium text-gray-700 mb-2">
                  {senderType === 'individual' ? 'Nationality *' : 'Country of Incorporation *'}
                </label>
                <select
                  id="nationality"
                  name="nationality"
                  value={formData.nationality}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                  <option value="">
                    Select {senderType === 'individual' ? 'Nationality' : 'Country'}
                  </option>
                  {[
                    'India',
                    'US',
                    'UK',
                    'Canada',
                    'Australia',
                    'Germany',
                    'France',
                  ].map((nation) => (
                    <option key={nation} value={nation}>
                      {nation}
                    </option>
                  ))}
                </select>
              </div>

              {/* KYC / KYB Type */}
              <div>
                <label htmlFor="kyc_type" className="block text-sm font-medium text-gray-700 mb-2">
                  {senderType === 'individual' ? 'KYC Document Type *' : 'KYB Document Type *'}
                </label>
                <select
                  id="kyc_type"
                  name="kyc_type"
                  value={formData.kyc_type}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                  <option value="">
                    {senderType === 'individual'
                      ? 'Select KYC Document Type'
                      : 'Select KYB Document Type'}
                  </option>
                  {(senderType === 'individual' ? individualKycTypes : businessKycTypes).map(
                    (type) => (
                      <option key={type} value={type}>
                        {type}
                      </option>
                    )
                  )}
                </select>
              </div>

              {/* KYC/KYB Number */}
              <div>
                <label htmlFor="kyc_number" className="block text-sm font-medium text-gray-700 mb-2">
                  {senderType === 'individual' ? 'KYC Number *' : 'KYB Number *'}
                </label>
                <input
                  id="kyc_number"
                  name="kyc_number"
                  type="text"
                  value={formData.kyc_number}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder={
                    senderType === 'individual' ? 'Enter KYC number' : 'Enter KYB number'
                  }
                />
              </div>
            </div>

            <button
              type="submit"
              disabled={isSubmitting}
              className="w-full px-4 py-3 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors disabled:bg-blue-300 disabled:cursor-not-allowed"
            >
              {isSubmitting
                ? senderType === 'individual'
                  ? 'Adding Individual Sender...'
                  : 'Adding Business Sender...'
                : senderType === 'individual'
                ? 'Add Individual Sender'
                : 'Add Business Sender'}
            </button>
          </form>
        </div>
      </Card>
    </div>
  );
};


const AddReceiverContent = () => {
  // Toggle receiver type: "individual" or "business"
  const [receiverType, setReceiverType] = useState('individual');

  // Combined list of senders (beneficiaries)
  const [beneficiaries, setBeneficiaries] = useState([]);
  const [selectedBeneficiaryId, setSelectedBeneficiaryId] = useState('');
  const [isLoadingBeneficiaries, setIsLoadingBeneficiaries] = useState(true);

  // Form state (common fields)
  const [formData, setFormData] = useState({
    receiver_name: '',
    ifsc_code: '',
    account_number: '',
    document_number: '', // optional field
  });

  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Fetch both individual and business senders and merge them into one list.
  useEffect(() => {
    const fetchSenders = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) throw new Error('Authentication token not found');

        const [individualRes, businessRes] = await Promise.all([
          fetch(`${API_BASE_URL}/senders/individual`, {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Accept': 'application/json'
            },
            credentials: 'include',
          }),
          fetch(`${API_BASE_URL}/senders/business`, {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Accept': 'application/json'
            },
            credentials: 'include',
          }),
        ]);

        if (!individualRes.ok || !businessRes.ok) {
          throw new Error('Failed to fetch senders');
        }

        const individualData = await individualRes.json();
        const businessData = await businessRes.json();

        // Map individual senders to a common structure.
        const mappedIndividuals = individualData.map((sender) => ({
          id: sender.id,
          name: sender.sender_name,
          type: 'Individual'
        }));

        // Map business senders to a common structure.
        const mappedBusinesses = businessData.map((sender) => ({
          id: sender.id,
          name: sender.business_name,
          type: 'Business'
        }));

        setBeneficiaries([...mappedIndividuals, ...mappedBusinesses]);
      } catch (err) {
        setError('Failed to load senders list. Please try again.');
      } finally {
        setIsLoadingBeneficiaries(false);
      }
    };

    fetchSenders();
  }, []);

  const handleBeneficiaryChange = (e) => {
    const beneficiaryId = e.target.value;
    setSelectedBeneficiaryId(beneficiaryId);
    setError('');
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
    setError('');
  };

  const validateForm = () => {
    if (!selectedBeneficiaryId) {
      setError('Please select a sender');
      return false;
    }
    if (!formData.receiver_name.trim()) {
      setError(receiverType === 'individual'
        ? 'Receiver name is required'
        : 'Business name is required');
      return false;
    }
    if (!formData.ifsc_code.trim() || !formData.account_number.trim()) {
      setError('Please provide bank account details');
      return false;
    }
    // Validate IFSC code format (example: 4 letters, a zero, then 6 alphanumeric)
    if (formData.ifsc_code && !/^[A-Z]{4}0[A-Z0-9]{6}$/.test(formData.ifsc_code.trim())) {
      setError('Invalid IFSC code format');
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    if (!validateForm()) return;

    setIsSubmitting(true);
    try {
      const formDataToSend = new FormData();
      // Append the selected sender id.
      formDataToSend.append('sender_id', selectedBeneficiaryId);
      // For individual receiver, use field "receiver_name"; for business, use "business_name"
      if (receiverType === 'individual') {
        formDataToSend.append('receiver_name', formData.receiver_name.trim());
      } else {
        formDataToSend.append('business_name', formData.receiver_name.trim());
      }
      formDataToSend.append('ifsc_code', formData.ifsc_code.trim());
      formDataToSend.append('account_number', formData.account_number.trim());
      if (formData.document_number.trim()) {
        formDataToSend.append('document_number', formData.document_number.trim());
      }

      // Choose endpoint based on receiverType.
      const endpoint =
        receiverType === 'individual'
          ? `${API_BASE_URL}/receivers/individual`
          : `${API_BASE_URL}/receivers/business`;

      const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
          // Do not set Content-Type; the browser will set the multipart boundary.
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: formDataToSend,
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error('Failed to add receiver');
      }

      // Clear fields (retain the selected sender for convenience).
      setFormData({
        receiver_name: '',
        ifsc_code: '',
        account_number: '',
        document_number: '',
      });

      alert('Receiver added successfully!');
    } catch (err) {
      setError(err.message || 'Failed to add receiver. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="p-6">
      <h2 className="text-2xl font-semibold mb-6">Add Receiver</h2>
      <Card>
        <CardContent className="pt-6">
          <form onSubmit={handleSubmit} className="space-y-6">
            {error && (
              <div className="p-4 mb-4 text-sm text-red-800 bg-red-100 rounded-lg">
                {error}
              </div>
            )}

            {/* Receiver Type Toggle */}
            <div className="mb-6">
              <label className="mr-4">
                <input
                  type="radio"
                  name="receiverType"
                  value="individual"
                  checked={receiverType === 'individual'}
                  onChange={() => setReceiverType('individual')}
                  className="mr-1"
                />
                Individual Receiver
              </label>
              <label>
                <input
                  type="radio"
                  name="receiverType"
                  value="business"
                  checked={receiverType === 'business'}
                  onChange={() => setReceiverType('business')}
                  className="mr-1"
                />
                Business Receiver
              </label>
            </div>

            {/* Sender Selection */}
            <div>
              <label htmlFor="beneficiary_id" className="block text-sm font-medium text-gray-700 mb-2">
                Select Sender *
              </label>
              {isLoadingBeneficiaries ? (
                <div className="text-sm text-gray-500">Loading senders...</div>
              ) : (
                <select
                  id="beneficiary_id"
                  name="beneficiary_id"
                  value={selectedBeneficiaryId}
                  onChange={handleBeneficiaryChange}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                  <option value="">Select a sender</option>
                  {beneficiaries.map((beneficiary) => (
                    <option key={beneficiary.id} value={beneficiary.id}>
                      {beneficiary.name} ({beneficiary.type})
                    </option>
                  ))}
                </select>
              )}
            </div>

            {/* Show the rest of the form only if a sender is selected */}
            {selectedBeneficiaryId && (
              <>
                {/* Receiver / Business Name */}
                <div>
                  <label htmlFor="receiver_name" className="block text-sm font-medium text-gray-700 mb-2">
                    {receiverType === 'individual'
                      ? 'Receiver Name *'
                      : 'Business Name *'}
                  </label>
                  <input
                    id="receiver_name"
                    name="receiver_name"
                    type="text"
                    value={formData.receiver_name}
                    onChange={handleInputChange}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    placeholder={
                      receiverType === 'individual'
                        ? 'Enter receiver name'
                        : 'Enter business name'
                    }
                  />
                </div>

                {/* Payment Information Section */}
                <div className="border-t pt-6">
                  <h3 className="text-lg font-medium mb-4">Payment Information</h3>

                  {/* IFSC Code */}
                  <div>
                    <label htmlFor="ifsc_code" className="block text-sm font-medium text-gray-700 mb-2">
                      IFSC Code *
                    </label>
                    <input
                      id="ifsc_code"
                      name="ifsc_code"
                      type="text"
                      value={formData.ifsc_code}
                      onChange={handleInputChange}
                      className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                      placeholder="Enter IFSC Code"
                    />
                  </div>

                  {/* Account Number */}
                  <div>
                    <label htmlFor="account_number" className="block text-sm font-medium text-gray-700 mb-2">
                      Account Number *
                    </label>
                    <input
                      id="account_number"
                      name="account_number"
                      type="text"
                      value={formData.account_number}
                      onChange={handleInputChange}
                      className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                      placeholder="Enter Account Number"
                    />
                  </div>

                  {/* Optional: Document Number */}
                  <div>
                    <label htmlFor="document_number" className="block text-sm font-medium text-gray-700 mb-2">
                      PAN Number (optional)
                    </label>
                    <input
                      id="document_number"
                      name="document_number"
                      type="text"
                      value={formData.document_number}
                      onChange={handleInputChange}
                      className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                      placeholder="Enter PAN Number"
                    />
                  </div>
                </div>

                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="w-full px-4 py-3 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors disabled:bg-blue-300 disabled:cursor-not-allowed"
                >
                  {isSubmitting ? 'Adding Receiver...' : 'Add Receiver'}
                </button>
              </>
            )}
          </form>
        </CardContent>
      </Card>
    </div>
  );
};




// List User Accounts Component
const ListAccountsContent = () => {
  // activeTab can be "individual" or "business"
  const [activeTab, setActiveTab] = useState('individual');
  const [senders, setSenders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Wrap fetchSenders in useCallback so its reference remains stable.
  const fetchSenders = useCallback(async () => {
    setLoading(true);
    setError(null);
    const token = localStorage.getItem('token');
    if (!token) {
      setError('Authentication token not found');
      setLoading(false);
      return;
    }

    let endpoint = activeTab === 'individual'
      ? `${API_BASE_URL}/senders/individual`
      : `${API_BASE_URL}/senders/business`;

    try {
      const response = await fetch(endpoint, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Accept': 'application/json',
        },
        credentials: 'include',
      });
      if (!response.ok) {
        throw new Error('Failed to fetch senders');
      }
      const data = await response.json();
      setSenders(data);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }, [activeTab]);

  useEffect(() => {
    fetchSenders();
  }, [fetchSenders]);

  // Show spinner if loading
  if (loading) {
    return (
      <div className="p-6 flex justify-center items-center">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  // Show error message if there's an error
  if (error) {
    return (
      <div className="p-6">
        <div className="bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded">
          Error: {error}
        </div>
      </div>
    );
  }

  // Render table header based on active sender type.
  const renderTableHeader = () => {
    if (activeTab === 'individual') {
      return (
        <tr>
          <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">Name</th>
          <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">Date of Birth</th>
          <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">Address</th>
          <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">Nationality</th>
          <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">KYC Type</th>
          <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">KYC Number</th>
          <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">Status</th>
        </tr>
      );
    } else {
      return (
        <tr>
          <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">Business Name</th>
          <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">Company Address</th>
          <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">Country of Incorporation</th>
          <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">KYB Type</th>
          <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">KYB Number</th>
          <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">Status</th>
        </tr>
      );
    }
  };

  // Render table rows based on active sender type.
  const renderTableRows = () => {
    if (activeTab === 'individual') {
      return senders.map((sender) => (
        <tr key={sender.id}>
          <td className="px-6 py-4 text-sm text-gray-900">{sender.sender_name}</td>
          <td className="px-6 py-4 text-sm text-gray-900">{sender.dob}</td>
          <td className="px-6 py-4 text-sm text-gray-900">{sender.current_address}</td>
          <td className="px-6 py-4 text-sm text-gray-900">{sender.nationality}</td>
          <td className="px-6 py-4 text-sm text-gray-900">{sender.kyc_type}</td>
          <td className="px-6 py-4 text-sm text-gray-900">{sender.kyc_number}</td>
          <td className="px-6 py-4 text-sm">
            <span
              className={`inline-flex px-2 py-1 rounded-full text-xs font-medium ${
                sender.kyc === true || sender.kyc === 'verified'
                  ? 'bg-green-100 text-green-800'
                  : 'bg-yellow-100 text-yellow-800'
              }`}
            >
              {sender.kyc === true || sender.kyc === 'verified' ? 'Verified' : 'Pending'}
            </span>
          </td>
        </tr>
      ));
    } else {
      return senders.map((sender) => (
        <tr key={sender.id}>
          <td className="px-6 py-4 text-sm text-gray-900">{sender.business_name}</td>
          <td className="px-6 py-4 text-sm text-gray-900">{sender.company_address}</td>
          <td className="px-6 py-4 text-sm text-gray-900">{sender.country_of_incorporation}</td>
          <td className="px-6 py-4 text-sm text-gray-900">{sender.kyb_type}</td>
          <td className="px-6 py-4 text-sm text-gray-900">{sender.kyb_number}</td>
          <td className="px-6 py-4 text-sm">
            <span
              className={`inline-flex px-2 py-1 rounded-full text-xs font-medium ${
                sender.kyb === true || sender.kyb === 'verified'
                  ? 'bg-green-100 text-green-800'
                  : 'bg-yellow-100 text-yellow-800'
              }`}
            >
              {sender.kyb === true || sender.kyb === 'verified' ? 'Verified' : 'Pending'}
            </span>
          </td>
        </tr>
      ));
    }
  };

  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-semibold">Senders</h2>
        <div className="flex space-x-4">
          <button
            onClick={() => setActiveTab('individual')}
            className={`px-4 py-2 rounded-md ${
              activeTab === 'individual' ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-800'
            }`}
          >
            Individual
          </button>
          <button
            onClick={() => setActiveTab('business')}
            className={`px-4 py-2 rounded-md ${
              activeTab === 'business' ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-800'
            }`}
          >
            Business
          </button>
        </div>
      </div>

      <Card>
        <div className="overflow-x-auto">
          <table className="w-full">
            <thead className="bg-gray-50">{renderTableHeader()}</thead>
            <tbody className="divide-y divide-gray-200">{renderTableRows()}</tbody>
          </table>
        </div>
        {senders.length === 0 && (
          <div className="text-center py-6 text-gray-500">
            No {activeTab} senders found. Click "Add New Sender" to add one.
          </div>
        )}
      </Card>
    </div>
  );
};



const ListReceiversContent = () => {
  // State for senders (combined from individual and business endpoints)
  const [senders, setSenders] = useState([]);
  const [selectedSenderId, setSelectedSenderId] = useState('');
  const [sendersLoading, setSendersLoading] = useState(true);

  // State for receivers (for selected sender)
  const [receivers, setReceivers] = useState([]);
  const [receiversLoading, setReceiversLoading] = useState(false);
  const [error, setError] = useState('');

  // Fetch senders by calling both individual and business endpoints.
  useEffect(() => {
    const fetchSenders = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) throw new Error('Authentication token not found');

        const [indRes, busRes] = await Promise.all([
          fetch(`${API_BASE_URL}/senders/individual`, {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Accept': 'application/json',
            },
            credentials: 'include',
          }),
          fetch(`${API_BASE_URL}/senders/business`, {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Accept': 'application/json',
            },
            credentials: 'include',
          }),
        ]);

        if (!indRes.ok) {
          throw new Error(`Failed to fetch individual senders: ${indRes.status}`);
        }
        if (!busRes.ok) {
          throw new Error(`Failed to fetch business senders: ${busRes.status}`);
        }

        const indData = await indRes.json();
        const busData = await busRes.json();

        // Map individual senders to a common structure.
        const mappedInd = indData.map((s) => ({
          id: s.id,
          name: s.sender_name,
          type: 'Individual',
        }));

        // Map business senders to a common structure.
        const mappedBus = busData.map((s) => ({
          id: s.id,
          name: s.business_name,
          type: 'Business',
        }));

        setSenders([...mappedInd, ...mappedBus]);
      } catch (err) {
        setError(err.message);
      } finally {
        setSendersLoading(false);
      }
    };

    fetchSenders();
  }, []);

  // When a sender is selected, fetch receivers for that sender.
  useEffect(() => {
    const fetchReceivers = async () => {
      if (!selectedSenderId) {
        setReceivers([]);
        return;
      }
      setReceiversLoading(true);
      setError('');
      try {
        const token = localStorage.getItem('token');
        if (!token) throw new Error('Authentication token not found');

        const indURL = `${API_BASE_URL}/receivers/individual?sender_id=${selectedSenderId}`;
        const busURL = `${API_BASE_URL}/receivers/business?sender_id=${selectedSenderId}`;

        const [indRes, busRes] = await Promise.all([
          fetch(indURL, {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Accept': 'application/json',
            },
            credentials: 'include',
          }),
          fetch(busURL, {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Accept': 'application/json',
            },
            credentials: 'include',
          }),
        ]);

        if (!indRes.ok) {
          throw new Error(`Failed to fetch individual receivers: ${indRes.status}`);
        }
        if (!busRes.ok) {
          throw new Error(`Failed to fetch business receivers: ${busRes.status}`);
        }

        const indData = await indRes.json();
        const busData = await busRes.json();

        // Map individual receivers to common structure.
        const mappedInd = indData.map((r) => ({
          id: r.id,
          receiver_name: r.receiver_name,
          ifsc_code: r.ifsc_code,
          account_number: r.account_number,
          type: 'Individual',
        }));

        // Map business receivers to common structure.
        const mappedBus = busData.map((r) => ({
          id: r.id,
          receiver_name: r.business_name, // mapped in business endpoint
          ifsc_code: r.ifsc_code,
          account_number: r.account_number,
          type: 'Business',
        }));

        setReceivers([...mappedInd, ...mappedBus]);
      } catch (err) {
        setError(err.message);
      } finally {
        setReceiversLoading(false);
      }
    };

    fetchReceivers();
  }, [selectedSenderId]);

  const handleSenderChange = (e) => {
    setSelectedSenderId(e.target.value);
    setError('');
  };

  if (sendersLoading) {
    return (
      <div className="p-6 flex justify-center items-center">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  return (
    <div className="p-6">
      <h2 className="text-2xl font-semibold mb-6">Receivers List</h2>

      {/* Sender Dropdown */}
      <div className="mb-6">
        <label htmlFor="sender" className="block text-sm font-medium text-gray-700 mb-2">
          Select Sender
        </label>
        <select
          id="sender"
          value={selectedSenderId}
          onChange={handleSenderChange}
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          <option value="">Select a sender</option>
          {senders.map((s) => (
            <option key={s.id} value={s.id}>
              {s.name} ({s.type})
            </option>
          ))}
        </select>
      </div>

      {error && (
        <div className="mb-6 bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded">
          Error: {error}
        </div>
      )}

      {selectedSenderId && (
        <div>
          <h3 className="text-xl font-semibold mb-4">Receivers</h3>
          {receiversLoading ? (
            <div className="flex justify-center items-center py-4">
              <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
            </div>
          ) : receivers.length > 0 ? (
            <div className="overflow-x-auto">
              <table className="w-full">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">
                      Receiver Name
                    </th>
                    <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">
                      Type
                    </th>
                    <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">
                      IFSC Code
                    </th>
                    <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">
                      Account Number
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {receivers.map((r) => (
                    <tr key={r.id}>
                      <td className="px-6 py-4 text-sm text-gray-900">{r.receiver_name}</td>
                      <td className="px-6 py-4 text-sm text-gray-900">{r.type}</td>
                      <td className="px-6 py-4 text-sm text-gray-900">{r.ifsc_code || '-'}</td>
                      <td className="px-6 py-4 text-sm text-gray-900">{r.account_number || '-'}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="text-center py-6 text-gray-500">
              No receivers found for the selected sender.
            </div>
          )}
        </div>
      )}
    </div>
  );
};



// Send Money Component
const SendMoneyContent = () => {
  // Amount and note state
  const [amountUSD, setAmountUSD] = useState('');
  const [amountINR, setAmountINR] = useState('');
  const [note, setNote] = useState('');

  // State for senders (both individual and business)
  const [senders, setSenders] = useState([]);
  const [selectedSender, setSelectedSender] = useState('');
  const [sendersLoading, setSendersLoading] = useState(true);

  // State for receivers (fetched for selected sender)
  const [receivers, setReceivers] = useState([]);
  const [selectedReceiver, setSelectedReceiver] = useState('');
  const [receiversLoading, setReceiversLoading] = useState(false);

  // Quote details: rate and quote id
  const [usdInrRate, setUsdInrRate] = useState(0);
  const [quoteId, setQuoteId] = useState('');
  const [loadingRate, setLoadingRate] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState('USDT');

  // Other state
  const [error, setError] = useState(null);
  const [sending, setSending] = useState(false);
  const [loading, setLoading] = useState(true);

  // Fetch senders from both individual and business endpoints and merge them.
  useEffect(() => {
    const fetchSenders = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) throw new Error('Authentication token not found');
        const [indRes, busRes] = await Promise.all([
          fetch(`${API_BASE_URL}/senders/individual`, {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Accept': 'application/json'
            },
            credentials: 'include'
          }),
          fetch(`${API_BASE_URL}/senders/business`, {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Accept': 'application/json'
            },
            credentials: 'include'
          })
        ]);
        if (!indRes.ok || !busRes.ok) {
          throw new Error('Failed to fetch senders');
        }
        const indData = await indRes.json();
        const busData = await busRes.json();
        const mappedInd = indData.map(s => ({
          id: s.id,
          name: s.sender_name,
          type: 'Individual'
        }));
        const mappedBus = busData.map(s => ({
          id: s.id,
          name: s.business_name,
          type: 'Business'
        }));
        setSenders([...mappedInd, ...mappedBus]);
      } catch (err) {
        setError(err.message);
      } finally {
        setSendersLoading(false);
        setLoading(false);
      }
    };

    fetchSenders();
  }, []);

  // Fetch receivers when a sender is selected.
  useEffect(() => {
    const fetchReceivers = async () => {
      if (!selectedSender) {
        setReceivers([]);
        return;
      }
      setReceiversLoading(true);
      setError(null);
      try {
        const token = localStorage.getItem('token');
        if (!token) throw new Error('Authentication token not found');
        const indURL = `${API_BASE_URL}/receivers/individual?sender_id=${selectedSender}`;
        const busURL = `${API_BASE_URL}/receivers/business?sender_id=${selectedSender}`;
        const [indRes, busRes] = await Promise.all([
          fetch(indURL, {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Accept': 'application/json'
            },
            credentials: 'include'
          }),
          fetch(busURL, {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Accept': 'application/json'
            },
            credentials: 'include'
          })
        ]);
        if (!indRes.ok || !busRes.ok) {
          throw new Error('Failed to fetch receivers');
        }
        const indData = await indRes.json();
        const busData = await busRes.json();
        const mappedInd = indData.map(r => ({
          id: r.id,
          receiver_name: r.receiver_name,
          ifsc_code: r.ifsc_code,
          account_number: r.account_number,
          type: 'Individual'
        }));
        const mappedBus = busData.map(r => ({
          id: r.id,
          receiver_name: r.business_name,
          ifsc_code: r.ifsc_code,
          account_number: r.account_number,
          type: 'Business'
        }));
        setReceivers([...mappedInd, ...mappedBus]);
      } catch (err) {
        setError(err.message);
      } finally {
        setReceiversLoading(false);
      }
    };

    fetchReceivers();
  }, [selectedSender]);

  // Wrap fetchRate in useCallback to avoid missing dependency warnings.
  const fetchRate = useCallback(async () => {
    setLoadingRate(true);
    try {
      const token = localStorage.getItem('token');
      if (!token) throw new Error('Authentication token not found');
      const res = await fetch(`${API_BASE_URL}/get_quote?currency=${selectedCurrency}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Accept': 'application/json'
        },
        credentials: 'include'
      });
      if (!res.ok) {
        throw new Error('Failed to fetch quote');
      }
      const data = await res.json();
      setUsdInrRate(parseFloat(data.price));
      setQuoteId(data.quote_id);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoadingRate(false);
    }
  }, [selectedCurrency]);

  useEffect(() => {
    fetchRate();
  }, [fetchRate]);

  // Handle amount changes: when one is updated, the other is converted.
  const handleAmountUSDChange = (e) => {
    const inputValue = e.target.value;
    if (inputValue === '' || /^\d*\.?\d{0,2}$/.test(inputValue)) {
      setAmountUSD(inputValue);
      if (inputValue && usdInrRate) {
        setAmountINR((parseFloat(inputValue) * usdInrRate).toFixed(2));
      } else {
        setAmountINR('');
      }
    }
  };

  const handleAmountINRChange = (e) => {
    const inputValue = e.target.value;
    if (inputValue === '' || /^\d*\.?\d{0,2}$/.test(inputValue)) {
      setAmountINR(inputValue);
      if (inputValue && usdInrRate) {
        setAmountUSD((parseFloat(inputValue) / usdInrRate).toFixed(2));
      } else {
        setAmountUSD('');
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedSender || !selectedReceiver || !amountUSD || !amountINR) {
      alert('Please select a sender, receiver, and enter valid amounts.');
      return;
    }
    const numAmountUSD = parseFloat(amountUSD);
    const numAmountINR = parseFloat(amountINR);
    if (isNaN(numAmountUSD) || isNaN(numAmountINR) || numAmountUSD <= 0 || numAmountINR <= 0) {
      alert('Please enter valid amounts.');
      return;
    }
    try {
      setSending(true);
      const token = localStorage.getItem('token');
      if (!token) throw new Error('Authentication token not found');
      const payload = {
        sender_id: selectedSender,
        receiver_id: selectedReceiver,
        inr_amount: numAmountINR,
        quote_id: quoteId, // using quoteId as a random identifier (adjust if needed)
        notes: note.trim() || null,
      };
      const res = await fetch(`${API_BASE_URL}/transfers/`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload),
        credentials: 'include'
      });
      const data = await res.json();
      if (!res.ok) {
        throw new Error(data.detail || 'Failed to send money');
      }
      // Reset form (retain selected sender)
      setAmountUSD('');
      setAmountINR('');
      setNote('');
      setSelectedReceiver('');
      alert('Transfer created!');
    } catch (err) {
      alert(err.message);
    } finally {
      setSending(false);
    }
  };

  return (
    <div className="p-6">
      <h2 className="text-2xl font-semibold mb-6">Send Money</h2>
      {loading ? (
        <div className="flex justify-center items-center py-8">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
        </div>
      ) : error ? (
        <div className="bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded mb-6">
          {error}
        </div>
      ) : (
        <>
          {/* Sender Dropdown */}
          {sendersLoading ? (
            <div className="flex justify-center items-center py-4">
              <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-gray-900"></div>
            </div>
          ) : (
            <div className="mb-6">
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Select Sender
              </label>
              <select
                value={selectedSender}
                onChange={(e) => setSelectedSender(e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                required
              >
                <option value="">Select sender</option>
                {senders.map(sender => (
                  <option key={sender.id} value={sender.id}>
                    {sender.name} ({sender.type})
                  </option>
                ))}
              </select>
            </div>
          )}

          {/* Receiver Dropdown */}
          {selectedSender && (
            <div className="mb-6">
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Select Receiver
              </label>
              {receiversLoading ? (
                <div className="flex justify-center py-2">
                  <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-gray-900"></div>
                </div>
              ) : (
                <select
                  value={selectedReceiver}
                  onChange={(e) => setSelectedReceiver(e.target.value)}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  required
                >
                  <option value="">Select receiver</option>
                  {receivers.map(receiver => (
                    <option key={receiver.id} value={receiver.id}>
                      {receiver.receiver_name}
                      {receiver.account_number ? ` - A/C: ${receiver.account_number}` : ''}
                    </option>
                  ))}
                </select>
              )}
              {receivers.length === 0 && !receiversLoading && (
                <p className="mt-2 text-sm text-gray-500">
                  No receivers found for this sender.
                </p>
              )}
            </div>
          )}

          {/* Currency Selection and Quote */}
          <div className="mb-6">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Select Currency for Quote
            </label>
            <select
              value={selectedCurrency}
              onChange={(e) => setSelectedCurrency(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="USDT">USDT</option>
              <option value="USDC">USDC</option>
            </select>
            <div className="mt-2 flex items-center gap-2">
              <input
                type="text"
                value={usdInrRate || ''}
                readOnly
                className="w-full px-3 py-2 border border-gray-300 rounded-md bg-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Quote Price"
              />
              <button
                type="button"
                onClick={fetchRate}
                className="px-3 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors"
                disabled={loadingRate}
              >
                {loadingRate ? 'Refreshing...' : 'Refresh Quote'}
              </button>
            </div>
          </div>

          {/* Amount Inputs */}
          <div className="mb-6 grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Amount (USD)
              </label>
              <input
                type="text"
                value={amountUSD}
                onChange={handleAmountUSDChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Enter amount in USD"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Amount (INR)
              </label>
              <input
                type="text"
                value={amountINR}
                onChange={handleAmountINRChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Enter amount in INR"
              />
            </div>
          </div>

          {/* Note */}
          <div className="mb-6">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Note (Optional)
            </label>
            <textarea
              value={note}
              onChange={(e) => setNote(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              rows={3}
              placeholder="Add a note"
            />
          </div>

          {/* Submit Button */}
          <button
            type="submit"
            onClick={handleSubmit}
            disabled={sending || !selectedSender || !selectedReceiver || !amountUSD || !amountINR}
            className="w-full px-4 py-3 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors disabled:bg-blue-300 disabled:cursor-not-allowed"
          >
            {sending ? 'Sending...' : 'Send Money'}
          </button>
        </>
      )}
    </div>
  );
};


const ListTransactionsContent = () => {
  // Search form state
  const [transactionId, setTransactionId] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  // Transactions state
  const [transactions, setTransactions] = useState([]);
  const [loadingTransactions, setLoadingTransactions] = useState(false);
  const [searchError, setSearchError] = useState('');

  // Mappings for sender and receiver names
  const [senderMap, setSenderMap] = useState({});
  const [receiverMap, setReceiverMap] = useState({});

  // Fetch senders (both individual and business) to build senderMap.
  useEffect(() => {
    const fetchSenders = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) throw new Error('Authentication token not found');
        const [indRes, busRes] = await Promise.all([
          fetch(`${API_BASE_URL}/senders/individual`, {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Accept': 'application/json',
            },
            credentials: 'include',
          }),
          fetch(`${API_BASE_URL}/senders/business`, {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Accept': 'application/json',
            },
            credentials: 'include',
          }),
        ]);
        if (!indRes.ok || !busRes.ok) {
          throw new Error('Failed to fetch senders');
        }
        const indData = await indRes.json();
        const busData = await busRes.json();
        const allSenders = [
          ...indData.map(s => ({ id: s.id, name: s.sender_name })),
          ...busData.map(s => ({ id: s.id, name: s.business_name }))
        ];
        const sMap = {};
        allSenders.forEach(s => { sMap[s.id] = s.name; });
        setSenderMap(sMap);
      } catch (err) {
        console.error(err);
        setSearchError(err.message);
      }
    };

    fetchSenders();
  }, []);

  // Fetch receivers for each sender to build a receiverMap.
  useEffect(() => {
    const fetchReceiversForAll = async () => {
      const token = localStorage.getItem('token');
      if (!token) return;
      try {
        const receiverMapping = {};
        // For each sender in senderMap, fetch receivers from both endpoints.
        const senderIds = Object.keys(senderMap);
        const promises = senderIds.map(async senderId => {
          const [indRes, busRes] = await Promise.all([
            fetch(`${API_BASE_URL}/receivers/individual?sender_id=${senderId}`, {
              headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
              },
              credentials: 'include',
            }),
            fetch(`${API_BASE_URL}/receivers/business?sender_id=${senderId}`, {
              headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
              },
              credentials: 'include',
            }),
          ]);
          let receivers = [];
          if (indRes.ok) {
            const indData = await indRes.json();
            receivers = receivers.concat(indData);
          }
          if (busRes.ok) {
            const busData = await busRes.json();
            receivers = receivers.concat(busData);
          }
          return receivers;
        });
        const results = await Promise.all(promises);
        results.flat().forEach(r => {
          // r.receiver_name is available for individual; for business, it's in business_name.
          const name = r.receiver_name || r.business_name;
          if (name) receiverMapping[r.id] = name;
        });
        setReceiverMap(receiverMapping);
      } catch (e) {
        console.error(e);
      }
    };

    if (Object.keys(senderMap).length > 0) {
      fetchReceiversForAll();
    }
  }, [senderMap]);

  // Handler for searching transactions.
  const handleSearch = async (e) => {
    e.preventDefault();
    setSearchError('');
    setTransactions([]);
    setLoadingTransactions(true);
    const token = localStorage.getItem('token');
    if (!token) {
      setSearchError('Authentication token not found');
      setLoadingTransactions(false);
      return;
    }
    try {
      let res;
      if (transactionId.trim() !== '') {
        // Search by transaction id.
        res = await fetch(`${API_BASE_URL}/transfers/${transactionId}`, {
          headers: { 'Authorization': `Bearer ${token}`, 'Accept': 'application/json' },
          credentials: 'include'
        });
        if (!res.ok) {
          const data = await res.json();
          throw new Error(data.detail || 'Failed to fetch transfer');
        }
        const tx = await res.json();
        setTransactions([tx]);
      } else {
        // Search by date range. If dates are not provided, backend defaults will apply.
        const query = new URLSearchParams();
        if (startDate) query.append('start_date', startDate);
        if (endDate) query.append('end_date', endDate);
        res = await fetch(`${API_BASE_URL}/transfers?${query.toString()}`, {
          headers: { 'Authorization': `Bearer ${token}`, 'Accept': 'application/json' },
          credentials: 'include'
        });
        if (!res.ok) {
          const data = await res.json();
          throw new Error(data.detail || 'Failed to fetch transfers');
        }
        const txs = await res.json();
        setTransactions(txs);
      }
    } catch (e) {
      setSearchError(e.message);
    } finally {
      setLoadingTransactions(false);
    }
  };

  return (
    <div className="p-6">
      <h2 className="text-2xl font-semibold mb-6">Transactions</h2>
      <form onSubmit={handleSearch} className="mb-6 space-y-4">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">Transaction ID</label>
            <input
              type="text"
              value={transactionId}
              onChange={(e) => setTransactionId(e.target.value)}
              placeholder="Enter transaction ID (optional)"
              className="mt-1 block w-full border border-gray-300 rounded-md px-3 py-2"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Start Date</label>
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              className="mt-1 block w-full border border-gray-300 rounded-md px-3 py-2"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">End Date</label>
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              className="mt-1 block w-full border border-gray-300 rounded-md px-3 py-2"
            />
          </div>
        </div>
        <button
          type="submit"
          className="mt-4 px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
        >
          Search
        </button>
      </form>
      {searchError && (
        <div className="mb-4 p-4 bg-red-50 border border-red-200 text-red-700 rounded">
          {searchError}
        </div>
      )}
      {loadingTransactions ? (
        <div className="flex justify-center items-center py-8">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
        </div>
      ) : transactions.length === 0 ? (
        <div className="text-center py-6 text-gray-500">No transactions found.</div>
      ) : (
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Transaction ID
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Date
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Sender
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Receiver
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  USD Amount
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  INR Amount
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Rate
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  UTR
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Status
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {transactions.map((tx) => (
                <tr key={tx.id}>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{tx.id}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {new Date(tx.datetime).toLocaleString()}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {senderMap[tx.sender_id] || tx.sender_id}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {receiverMap[tx.receiver_id] || tx.receiver_id}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{tx.usd_amount}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{tx.transferred_inr}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{tx.usd_inr_rate}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {tx.utr || '-'}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{tx.status}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};


const Dashboard = () => {
  const { user, logout } = useAuth();
  const [selectedOption, setSelectedOption] = useState('balance');

  useEffect(() => {
    console.log('Current user:', user);
    console.log('KYB status:', user?.kyb);
    console.log('KYB type:', typeof user?.kyb);
  }, [user]);

  // Add new menu item "List Transactions"
  const menuItems = [
    { id: 'balance', label: 'Check Balance', icon: PiggyBank },
    { id: 'wallets', label: 'Add USD Balance', icon: Wallet },
    { id: 'add-sender-account', label: 'Add Sender Account', icon: UserPlus },
    { id: 'list-accounts', label: 'List Sender Accounts', icon: List },
    { id: 'add-receiver-account', label: 'Add Receiver Account', icon: UserPlus },
    { id: 'list-receiver-accounts', label: 'List Receiver Accounts', icon: List },
    { id: 'send-money', label: 'Send Money', icon: SendHorizontal },
    { id: 'list-transactions', label: 'List Transactions', icon: List } // New option
  ];

  const renderContent = () => {
    switch (selectedOption) {
      case 'balance':
        return <BalanceContent onSelect={setSelectedOption} />;
      case 'wallets':
        return <WalletsContent />;
      case 'add-sender-account':
        return <AddSenderContent />;
      case 'list-accounts':
        return <ListAccountsContent />;
      case 'add-receiver-account':
        return <AddReceiverContent />;
      case 'list-receiver-accounts':
        return <ListReceiversContent />;
      case 'send-money':
        return <SendMoneyContent />;
      case 'list-transactions':
        return <ListTransactionsContent />; // New case
      default:
        return (
          <div className="p-6">
            <h2 className="text-2xl font-semibold mb-4">
              {menuItems.find(item => item.id === selectedOption)?.label}
            </h2>
            <div className="bg-white p-6 rounded-lg shadow">
              <p className="text-gray-600">This feature is under development...</p>
            </div>
          </div>
        );
    }
  };

  // If KYB is not verified, show the KYB pending message.
  if (!Boolean(user?.kyb)) {
    return (
      <div className="min-h-screen bg-gray-50">
        <nav className="bg-white shadow">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex justify-between h-16">
              <div className="flex items-center">
                <h1 className="text-xl font-semibold">Dashboard</h1>
              </div>
              <div className="flex items-center space-x-4">
                <span className="text-gray-700">Hi, {user?.name || user?.email}</span>
                <button
                  onClick={logout}
                  className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700"
                >
                  Logout
                </button>
              </div>
            </div>
          </div>
        </nav>
        <main className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
          <div className="px-4 py-6 sm:px-0">
            <div className="border-4 border-dashed border-gray-200 rounded-lg h-96 p-4">
              <div className="text-center">
                <div className="inline-flex items-center px-4 py-2 mb-4 bg-yellow-100 text-yellow-800 rounded-md">
                  <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                  Verification in Progress
                </div>
                <h2 className="text-2xl font-semibold text-gray-800">KYB Verification Pending</h2>
                <p className="mt-2 text-gray-600">Our Team will soon contact you for your KYB verification.</p>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }

  // Main dashboard with sidebar and content.
  return (
    <div className="min-h-screen bg-gray-50">
      <nav className="bg-white shadow">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex items-center">
              <h1 className="text-xl font-semibold">Dashboard</h1>
            </div>
            <div className="flex items-center space-x-4">
              <span className="text-gray-700">Hi, {user?.name || user?.email}</span>
              <button
                onClick={logout}
                className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700"
              >
                Logout
              </button>
            </div>
          </div>
        </div>
      </nav>

      <div className="flex">
        {/* Sidebar */}
        <div className="w-64 bg-white shadow-lg min-h-screen">
          <nav className="mt-8">
            <div className="px-2 space-y-1">
              {menuItems.map(({ id, label, icon: Icon }) => (
                <button
                  key={id}
                  onClick={() => setSelectedOption(id)}
                  className={`w-full flex items-center px-4 py-3 text-sm font-medium rounded-md transition-colors ${
                    selectedOption === id
                      ? 'bg-blue-50 text-blue-700'
                      : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900'
                  }`}
                >
                  <Icon className="mr-3 h-5 w-5" />
                  {label}
                </button>
              ))}
            </div>
          </nav>
        </div>

        {/* Main Content */}
        <div className="flex-1 bg-gray-50">
          <main className="py-6">
            {renderContent()}
          </main>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;