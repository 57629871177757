import lowPrice from "../../assets/imgs/low-price.svg";
import alwaysOn from "../../assets/imgs/alwaysOn.svg";

const Upcoming = () => {
    return (
        <main className="w-full flex flex-col items-center justify-center gap-5 md:gap-5 lg:mx-6 xl:mx-10 my-20">
            <h1 className="text-center md:text-start text-3xl lg:text-5xl font-intertightbold">
                Upcoming Features
            </h1>

            <section className="flex flex-col md:flex-row items-center justify-center gap-7 w-full my-10">
                <div className="rounded-xl bg-[#F6F9FC] p-5 flex flex-col justify-center w-72 shadow-sm h-64">
                    <img src={lowPrice} alt="low price" className="w-16" />

                    <h2 className="font-intertightbold text-xl">US Dollar Accounts</h2>

                    <p className="font-helveticanow my-4 text-sm">
                        Secure and stable USD storage.
                    </p>
                </div>
                <div className="rounded-xl bg-[#F6F9FC] p-5 flex flex-col justify-center w-72 shadow-sm h-64">
                    <img src={alwaysOn} alt="low price" className="w-16" />

                    <h2 className="font-intertightbold text-xl">Earn up to 10% APY</h2>

                    <p className="font-helveticanow my-4 text-sm">
                        Treasury yield options on stablecoin deposits.
                    </p>
                </div>
            </section>
        </main>
    );
};

export default Upcoming;
