import React, { useState, useCallback } from 'react';
import { useAuth } from '../context/AuthContext';
import { LockIcon, MailIcon, UserIcon, AlertCircle, EyeIcon, EyeOffIcon, X } from 'lucide-react';
import text from "../assets/imgs/superstable_text.svg";
import logo from "../assets/imgs/superstable_logo.svg";

const MAX_LOGIN_ATTEMPTS = 5;
const LOCKOUT_DURATION = 15 * 60 * 1000; // 15 minutes in milliseconds
const BASE_URL = "https://superstablecontainerapp.politecoast-3c06f7f1.eastus.azurecontainerapps.io"

const AuthForm = () => {
  const { login } = useAuth();
  const [isLogin, setIsLogin] = useState(true);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loginAttempts, setLoginAttempts] = useState(0);
  const [lockoutUntil, setLockoutUntil] = useState(null);

  const { setShowAuth } = useAuth();


  const [formData, setFormData] = useState({
    email: '',
    password: '',
    confirmPassword: '',
    name: '',
    role: 'user'
  });

  // Validation states
  const [validations, setValidations] = useState({
    email: { isValid: true, message: '' },
    password: { isValid: true, message: '' },
    confirmPassword: { isValid: true, message: '' },
    name: { isValid: true, message: '' }
  });

  // Check if user is locked out
  const isLockedOut = useCallback(() => {
    if (!lockoutUntil) return false;
    if (new Date() > lockoutUntil) {
      setLockoutUntil(null);
      setLoginAttempts(0);
      return false;
    }
    return true;
  }, [lockoutUntil]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));

    // Validate email
    if (name === 'email') {
      const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
      setValidations(prev => ({
        ...prev,
        email: {
          isValid: emailRegex.test(value),
          message: emailRegex.test(value) ? '' : 'Please enter a valid email'
        }
      }));
    }

    // Add this to handleInputChange
    if (name === 'name' && !isLogin) {
      const isValid = value.trim().length > 0;
      setValidations(prev => ({
        ...prev,
        name: {
          isValid,
          message: isValid ? '' : 'Name is required'
        }
      }));
    }

    // Validate password
    if (name === 'password' && !isLogin) {
      const isValid = value.length >= 8;
      setValidations(prev => ({
        ...prev,
        password: {
          isValid,
          message: isValid ? '' : 'Password must be at least 8 characters'
        }
      }));
    }

    // Validate confirm password
    if (name === 'confirmPassword') {
      setValidations(prev => ({
        ...prev,
        confirmPassword: {
          isValid: value === formData.password,
          message: value === formData.password ? '' : 'Passwords do not match'
        }
      }));
    }
  };

  const getErrorMessage = (error) => {
    if (typeof error === 'string') return error;
    if (error?.message) return error.message;
    return 'An unexpected error occurred';
  };

  const resetForm = () => {
    setFormData({
      email: '',
      password: '',
      confirmPassword: '',
      name: '',
      role: 'user'
    });
    setError('');
    setValidations({
      email: { isValid: true, message: '' },
      password: { isValid: true, message: '' },
      confirmPassword: { isValid: true, message: '' }
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (loading) return; // Prevent multiple submissions
    setLoading(true); // Set loading state immediately
    setError('');

    if (isLockedOut()) {
      const minutesLeft = Math.ceil((lockoutUntil - new Date()) / 1000 / 60);
      setError(`Too many login attempts. Please try again in ${minutesLeft} minutes.`);
      return;
    }

    if (!isLogin && !formData.name.trim()) {
      setError('Name is required');
      return;
    }

    try {
      if (isLogin) {
        setLoginAttempts(prev => prev + 1);
        if (loginAttempts + 1 >= MAX_LOGIN_ATTEMPTS) {
          setLockoutUntil(new Date(Date.now() + LOCKOUT_DURATION));
          setError(`Too many login attempts. Please try again in 15 minutes.`);
          return;
        }
        const formDataLogin = new URLSearchParams();
        formDataLogin.append('username', formData.email);
        formDataLogin.append('password', formData.password);

        const result = await login(formDataLogin);
        if (!result.success) {
          setError(getErrorMessage(result.error));
        }
      } else {
        const response = await fetch(`${BASE_URL}/api/signup`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
          body: JSON.stringify({
            email: formData.email,
            password: formData.password,
            name: formData.name || null,
            role: 'user'
          }),
        });
        const data = await response.json();
        if (response.ok) {
          const formDataLogin = new URLSearchParams();
          formDataLogin.append('username', formData.email);
          formDataLogin.append('password', formData.password);

          const loginResult = await login(formDataLogin);
          if (!loginResult.success) {
            setError(getErrorMessage(loginResult.error));
          }
        } else {
          setError(getErrorMessage(data));
        }
      }
    } catch (error) {
      setError(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center ">

      <div className="max-w-md w-full space-y-4 bg-white p-8 rounded-xl shadow-lg animate-fadeIn flex flex-col">
        <div className="self-end z-10">
          <button
            onClick={() => setShowAuth(false)}
            className="p-2 rounded-full hover:bg-gray-100 text-gray-500 hover:text-gray-700 transition-colors"
            aria-label="Close modal"
          >
            <X className="h-5 w-5" />
          </button>
        </div>
        <div className="flex justify-center items-center flex-col gap-4 relative bottom-5">
          <div className="flex items-center gap-3 animate-slideDown mr-4">
            <a href="/">
              <img className="" src={logo} alt="logo" />
            </a>
            <a href="/">
              <img className="w-44" src={text} alt="logo" />
            </a>
          </div>
          <h2 className="text-2xl font-intertight font-semibold text-gray-900">
            {isLogin ? 'Welcome back!' : 'Join SuperStable'}
          </h2>
        </div>

        {error && (
          <div className="bg-red-50 border-l-4 border-red-400 p-4 rounded-md animate-shake" role="alert">
            <div className="flex">
              <AlertCircle className="h-5 w-5 text-red-400" />
              <p className="ml-3 text-sm text-red-700">{error}</p>
            </div>
          </div>
        )}

        <form className="mt-8 space-y-6" onSubmit={handleSubmit} noValidate>
          {!isLogin && (
            <div className="relative group animate-slideRight font-helveticanow">
              <UserIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
              <input
                id="name"
                name="name"
                type="text"
                required={!isLogin}
                className="pl-10 appearance-none rounded-lg relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent sm:text-sm"
                placeholder="Full Name"
                value={formData.name}
                onChange={handleInputChange}
                disabled={loading}
                aria-label="Full name"
              />

            </div>
          )}

          <div className="relative group animate-slideRight">
            <MailIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
            <input
              id="email"
              name="email"
              type="email"
              autoComplete="email"
              required
              className={`pl-10 appearance-none rounded-lg relative block w-full px-3 py-2 border ${validations.email.isValid ? 'border-gray-300' : 'border-red-300'
                } placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent sm:text-sm`}
              placeholder="Email address"
              value={formData.email}
              onChange={handleInputChange}
              disabled={loading}
              aria-invalid={!validations.email.isValid}
            />
            {!validations.email.isValid && (
              <p className="mt-1 text-sm text-red-600">{validations.email.message}</p>
            )}
          </div>

          <div className="relative group animate-slideRight">
            <LockIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
            <input
              id="password"
              name="password"
              type={showPassword ? "text" : "password"}
              autoComplete={isLogin ? "current-password" : "new-password"}
              required
              className={`pl-10 pr-12 appearance-none rounded-lg relative block w-full px-3 py-2 border ${validations.password.isValid ? 'border-gray-300' : 'border-red-300'
                } placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent sm:text-sm`}
              placeholder="Password"
              value={formData.password}
              onChange={handleInputChange}
              disabled={loading}
              aria-invalid={!validations.password.isValid}
            />
            <button
              type="button"
              onClick={() => setShowPassword(!showPassword)}
              className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-blue-500 transition-colors"
              aria-label={showPassword ? "Hide password" : "Show password"}
            >
              {showPassword ? <EyeOffIcon className="h-5 w-5" /> : <EyeIcon className="h-5 w-5" />}
            </button>
            {!validations.password.isValid && (
              <p className="mt-1 text-sm text-red-600">{validations.password.message}</p>
            )}
          </div>

          {!isLogin && (
            <div className="relative group animate-slideRight">
              <LockIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
              <input
                id="confirmPassword"
                name="confirmPassword"
                type={showConfirmPassword ? "text" : "password"}
                autoComplete="new-password"
                required
                className={`pl-10 pr-12 appearance-none rounded-lg relative block w-full px-3 py-2 border ${validations.confirmPassword.isValid ? 'border-gray-300' : 'border-red-300'
                  } placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent sm:text-sm`}
                placeholder="Confirm Password"
                value={formData.confirmPassword}
                onChange={handleInputChange}
                disabled={loading}
              />
              <button
                type="button"
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-blue-500 transition-colors"
                aria-label={showConfirmPassword ? "Hide password" : "Show password"}
              >
                {showConfirmPassword ? <EyeOffIcon className="h-5 w-5" /> : <EyeIcon className="h-5 w-5" />}
              </button>
              {!validations.confirmPassword.isValid && (
                <p className="mt-1 text-sm text-red-600">{validations.confirmPassword.message}</p>
              )}
            </div>
          )}

          <button
            type="submit"
            disabled={loading || isLockedOut()}
            className="group relative w-full flex justify-center py-2.5 px-4 border border-transparent text-sm font-medium rounded-lg text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed transition-all duration-200 hover:scale-[1.02] active:scale-[0.98]"
          >
            {loading ? (
              <div className="flex items-center">
                <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="https://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                {isLogin ? 'Signing in...' : 'Creating account...'}
              </div>
            ) : (
              <span className="flex items-center">
                <LockIcon className="w-5 h-5 mr-2" />
                {isLogin ? 'Sign in' : 'Create account'}
              </span>
            )}
          </button>
        </form>

        <div className="text-center animate-fadeIn">
          <button
            type="button"
            className="text-sm text-blue-600 hover:text-blue-500 font-medium transition-colors duration-200 hover:underline"
            onClick={() => {
              setIsLogin(!isLogin);
              resetForm();
            }}
            disabled={loading}
          >
            {isLogin ? 'Need an account? Sign up' : 'Already have an account? Sign in'}
          </button>
        </div>
      </div>

      <style jsx>{`
        @keyframes fadeIn {
          from { opacity: 0; }
          to { opacity: 1; }
        }

        @keyframes slideDown {
          from { transform: translateY(-20px); opacity: 0; }
          to { transform: translateY(0); opacity: 1; }
        }

        @keyframes slideRight {
          from { transform: translateX(-20px); opacity: 0; }
          to { transform: translateX(0); opacity: 1; }
        }

        @keyframes shake {
          0%, 100% { transform: translateX(0); }
          25% { transform: translateX(-5px); }
          75% { transform: translateX(5px); }
        }

        .animate-fadeIn {
          animation: fadeIn 0.5s ease-out;
        }

        .animate-slideDown {
          animation: slideDown 0.5s ease-out;
        }

        .animate-slideRight {
          animation: slideRight 0.5s ease-out;
        }

        .animate-shake {
          animation: shake 0.5s ease-in-out;
        }
      `}</style>
    </div>
  );
};

export default AuthForm;