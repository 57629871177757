import { useState, useEffect } from "react";
import { fiats, stablecoins } from "../../utils/utils";
import walletIcon from "../../assets/imgs/walleticon.svg";
import timerIcon from "../../assets/imgs/timericon.svg";
import { useAuth } from "../../context/AuthContext";

const fetchExchangeRate = async (to) => {
    const response = await fetch(
        `https://api.freecurrencyapi.com/v1/latest?apikey=fca_live_LJwr4vIX9QriMqbXfL1tSlhhxSSKUv2NZsAyfjtA&base_currency=USD`
    );
    const data = await response.json();
    return data.data[to] ? data.data[to] : null;
};

const HeroDEXWidget = () => {
    const { setShowAuth } = useAuth();

    const [amount, setAmount] = useState("1000");
    const [currencyFrom, setCurrencyFrom] = useState("USDT");
    const [currencyTo, setCurrencyTo] = useState("INR");
    const [exchangeRate, setExchangeRate] = useState(null);

    const selectedCoin = stablecoins.find((coin) => coin.name === currencyFrom);
    const selectedFiat = fiats.find((fiat) => fiat.name === currencyTo);

    useEffect(() => {
        const getRate = async () => {
            const rate = await fetchExchangeRate(currencyTo);
            if (rate) {
                setExchangeRate(rate + 0.35);
            }
        };
        getRate();
    }, [currencyFrom, currencyTo]);

    const recipientAmount = exchangeRate
        ? (parseFloat(amount) * exchangeRate).toFixed(2)
        : "Loading...";

    return (
        <div className="min-w-max md:min-w-[28rem] mx-auto p-6 bg-white shadow-md rounded-lg border border-gray-200 drop-shadow-2xl">
            <h2 className="text-lg font-intertight text-[#305178]">Send Money</h2>

            <div className="mt-3">
                {/* <label className="block text-gray-600 text-sm mb-1">Amount</label> */}
                <div className="flex items-center border rounded-lg overflow-hidden">
                    <input
                        value={amount}
                        onChange={(e) => setAmount(e.target.value)}
                        className="w-full p-2 border-none outline-none font-helveticanow font-bold"
                    />
                    <div className="p-2 bg-gray-100 border-l flex items-center gap-2">
                        {selectedCoin && (
                            <img
                                src={selectedCoin.logo}
                                alt={selectedCoin.name}
                                className="w-5 h-5"
                            />
                        )}
                        <select
                            value={currencyFrom}
                            onChange={(e) => setCurrencyFrom(e.target.value)}
                            className="bg-gray-100 text-gray-700 outline-none w-fit mr-3 font-bold font-intertight"
                        >
                            {stablecoins.map((coin) => (
                                <option key={coin.name} value={coin.name}>
                                    {coin.name}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            </div>

            <div className="mt-4 text-sm text-gray-600 space-y-1 font-plusjakarta">
                <div className="flex items-center gap-2">
                    <img src={walletIcon} alt="wallet icon" className="w-5 h-5" />
                    <p>
                        Our Exchange Rate:{" "}
                        <strong>
                            {exchangeRate
                                ? `1 ${currencyFrom} = ${exchangeRate.toFixed(4)} ${currencyTo}`
                                : "Loading..."}
                        </strong>
                    </p>
                </div>
                <div className="flex items-center gap-2">
                    <img src={timerIcon} alt="timer icon" className="w-5 h-5" />
                    <p>
                        Delivery Time: <strong>0–30 mins</strong>
                    </p>
                </div>
                <div className="flex items-center gap-2">
                    <img src={walletIcon} alt="wallet icon" className="w-5 h-5" />
                    <p>
                        Our Transfer Fees: <strong>0.00 Estimated</strong>
                    </p>
                </div>
            </div>

            <div className="mt-5">
                <label className="block text-gray-600 text-sm mb-1 font-intertight">
                    Recipient gets
                </label>
                <div className="flex items-center border rounded-lg overflow-hidden">
                    <input
                        value={recipientAmount}
                        className="w-full p-2 border-none outline-none font-helveticanow font-bold bg-gray-50"
                        readOnly
                    />
                    <div className="p-2 bg-gray-100 border-l flex items-center gap-2">
                        {selectedFiat && (
                            <img
                                src={selectedFiat.logo}
                                alt={selectedFiat.name}
                                className="w-5 h-5"
                            />
                        )}
                        <select
                            value={currencyTo}
                            onChange={(e) => setCurrencyTo(e.target.value)}
                            className="bg-gray-100 text-gray-700 outline-none w-fit mr-3 font-bold font-intertight"
                        >
                            {fiats.map((fiat) => (
                                <option key={fiat.name} value={fiat.name}>
                                    {fiat.name}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            </div>

            <button onClick={() => setShowAuth(true)} className="w-full mt-6 p-3 bg-[#41D87E] text-gray-100 font-semibold rounded-lg hover:bg-green-600 font-helveticanow cursor-pointer">
                Send Money
            </button>
        </div>
    );
};

export default HeroDEXWidget;
