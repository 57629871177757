import { clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import usdtLogo from "../assets/imgs/usdtlogo.svg"
import usdcLogo from "../assets/imgs/usdcLogo.png"
import inrLogo from "../assets/imgs/indianLogo.png"
// import phpLogo from "../assets/imgs/phillipines.svg"
import india from "../assets/imgs/india.svg";
import phill from "../assets/imgs/phillipines.svg";
import vietnam from "../assets/imgs/vietnam.svg";
import mexico from "../assets/imgs/mexico.svg";
import indo from "../assets/imgs/indonesia.svg";

export function cn(...inputs) {
    return twMerge(clsx(inputs));
}

export const stablecoins = [
    { name: "USDT", logo: usdtLogo },
    { name: "USDC", logo: usdcLogo }
]

export const fiats = [
    // { name: "USD", logo: usdLogo },
    { name: "INR", logo: inrLogo },
    // { name: "PHP", logo: phpLogo }
]

export const countries = [
    { countryName: "India", logo: india },
    { countryName: "Philippines", logo: phill },
    { countryName: "Indonesia", logo: indo },
    { countryName: "Vietnam", logo: vietnam },
    { countryName: "Mexico", logo: mexico },
]

